import * as t from './actionTypes';

const initialState = {
    isEditProjectDialogOpen: false,
    isDeleteProjectDialogOpen: false,
    isEditCardDialogOpen: false,
    isReleaseFlyoutOpen: false,
    isEditModeEnabled: true,
    showClosed: false,
    // Tracks the type of card being edited (ex. Goal, Task)
    editCardType: null,
    // Tracks the actual card detail being edited (ex. Title, Labels)
    editCard: null,
    // Tracks the release being displayed in the flyout
    release: null,
    // Tracks the goals that are currently collapsed
    collapsedGoals: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        case t.TOGGLE_EDIT_DIALOG:
            const isEditCardDialogOpen = !state.isEditCardDialogOpen;
            return {
                ...state,
                isEditCardDialogOpen,
                editCardType: isEditCardDialogOpen ? action.cardType : null,
                editCard: isEditCardDialogOpen ? action.card : null
            };
        case t.UPDATE_CARD:
            return {
                ...state,
                editCard: action.card
            };
        case t.TOGGLE_EDIT_PROJECT_DIALOG:
            const isEditProjectDialogOpen = !state.isEditProjectDialogOpen;
            return {
                ...state,
                isEditProjectDialogOpen
            };
        case t.TOGGLE_DELETE_PROJECT_DIALOG:
            const isDeleteProjectDialogOpen = !state.isDeleteProjectDialogOpen;
            return {
                ...state,
                isDeleteProjectDialogOpen
            };
        case t.TOGGLE_RELEASE_FLYOUT:
            const isReleaseFlyoutOpen = !state.isReleaseFlyoutOpen;
            return {
                ...state,
                isReleaseFlyoutOpen,
                release: action.release
            };
        case t.TOGGLE_EDIT_MODE:
            const isEditModeEnabled = !state.isEditModeEnabled;
            return {
                ...state,
                isEditModeEnabled
            };
        case t.TOGGLE_GOAL:
            if (state.collapsedGoals.includes(action.goalId)) {
                return {
                    ...state,
                    collapsedGoals: [...state.collapsedGoals.filter(g => g !== action.goalId)]
                };
            }
            else {
                return {
                    ...state,
                    collapsedGoals: state.collapsedGoals.concat(action.goalId)
                };
            }
        case t.TOGGLE_SHOW_CLOSED:
            const showClosed = !state.showClosed;
            return {
                ...state,
                showClosed
            };
        case t.RESET_STORYMAP:
            return { ...initialState };
        default:
            return state;
    }
};