import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore/memory';

const config = {
    apiKey: 'AIzaSyCPk6JOEMS6VxvSemueuLCuXIFBeFlt1Z8',
    authDomain: 'fable-d02d5.firebaseapp.com',
    databaseURL: 'https://fable-d02d5.firebaseio.com',
    projectId: 'fable-d02d5',
    storageBucket: 'fable-d02d5.appspot.com',
    messagingSenderId: '1011446560884'
};

app.initializeApp(config);

app.firestore().settings({ });

// app.firestore.setLogLevel('debug');

export default app;