import React from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function UserMenu() {
    const firebase = useFirebase();
    const history = useHistory();

    const auth = useSelector(({ auth }) => auth);

    async function logout(e) {
        e.preventDefault();

        await firebase.auth().signOut();

        history.push('/login');
    }

    if (!auth?.user?.uid) {
        return (
            <ul className='navbar-nav'>
                <li className='nav-item' key='register'>
                    <RouterLink className='nav-link' to='/register'>Register</RouterLink>
                </li>
                <li className='nav-item' key='login'>
                    <RouterLink className='nav-link' to='/login'>Login</RouterLink>
                </li>
            </ul>
        );
    }

    const { user } = auth;

    return (
        <ul className='navbar-nav'>
            <li className='nav-item dropdown d-none d-lg-flex'>
                <a href='#' className='nav-link dropdown-toggle' data-toggle='dropdown' role='button'
                    aria-haspopup='true' aria-expanded='false'>
                    { false ? <img className='user-avatar' src={user.photoURL} /> : null }
                    <span>
                        {user.name || user.email}
                    </span>
                    <span className='caret'></span>
                </a>
                <div className='dropdown-menu dropdown-menu-right'>
                    <RouterLink className='dropdown-item' to='/profile'><FontAwesomeIcon icon={['fas', 'user']} /> Profile</RouterLink>
                    <div className='dropdown-divider'></div>
                    <a href='#' className='dropdown-item' onClick={logout}><FontAwesomeIcon icon={['fas', 'lock']} /> Logout</a>
                </div>
            </li>
            <li className='nav-item d-block d-lg-none'>
                <RouterLink className='nav-link' to='/profile'><FontAwesomeIcon icon={['fas', 'user']} /> Profile</RouterLink>
            </li>
            <li className='nav-item d-block d-lg-none'>
                <div className='dropdown-divider'></div>
                <a className='nav-link' onClick={logout}><FontAwesomeIcon icon={['fas', 'lock']} /> Logout</a>
            </li>
        </ul>
    );
}