import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { setUser } from 'modules/auth/actions';
import FormActions from 'common/components/FormActions';

export default function DeleteAccount({ isOpen, setIsOpen }) {
    const dispatch = useDispatch();
    const firebase = useFirebase();
    const firestore = useFirestore();
    const history = useHistory();

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const user = useSelector(({ auth: { user }}) => user);

    const [isBusy, setIsBusy] = useState(false);
    const [errorMessage, setError] = useState(null);

    useEffect(() => {
        setError(null);
        emailRef.current.focus();
    }, [isOpen])

    async function requestDelete(e) {
        e && e.preventDefault();

        if (emailRef.current.value !== user.email) {
            return;
        }

        try {
            await setIsBusy(true);

            let authFailed = false;

            try {
                await firebase.auth().signInWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);
            }
            catch (authError) {
                setError('Error encountered while validating current password: ' + authError?.message || '')
                setIsBusy(false);
                authFailed = true;
            }

            if (authFailed) {
                return;
            }

            if (user.teamId) {
                let profiles = await firestore.collection('profiles').where('teamId', '==', user.teamId).get();
                const isLastPersonOnTeam = profiles && profiles.size === 1;

                if (isLastPersonOnTeam) {
                    let storymaps = await firestore.collection('projects').where('teamId', '==', user.teamId).get();
                    
                    if (storymaps && storymaps.size > 0) {
                        setError('You are the last person in your team. Accordingly, we cannot delete your account while there are still storymaps associated to your team. Please delete those first.');
                        setIsBusy(false);
                        return;
                    }

                    await firestore.collection('teams').doc(user.teamId).delete();
                }
            }
            else {
                let requests = await firestore.collection('requests').where('uid', '==', user.uid).get();

                if (requests && requests.size > 0) {
                    for (let i = 0, len = requests.size; i < len; i++) {
                        let requestId = requests.docs[i].id;
                        await firestore.collection('requests').doc(requestId).delete();
                    }
                }
            }

            await firestore.collection('profiles').doc(user.uid).delete();
            await firebase.auth().currentUser.delete();

            await dispatch(setUser(null));

            await setIsBusy(false);
            await setIsOpen(false);

            history.push('/');
        }
        catch (deleteError) {
            setError(deleteError?.message || 'unknown error');
            setIsBusy(false);
        }
    }

    function cancel() {
        emailRef.current.value = '';
        passwordRef.current.value = '';

        setIsOpen(false);
    }

    function onKeyPress(e) {
        if (e.key === 'Enter') {
            requestDelete();
        }
    }

    const classes = classNames('modal', {
        'modal-open': isOpen
    });

    return (
        <div className={classes}>
            <div className='card bg-warning p-3 mb-4'>
                Note: Deleting your account will not delete any data associated with your team, just your personal information. If you're the last member of your team, please delete your story maps before proceeding.
            </div>

            <div className='form-group form-row'>
                <label htmlFor='emailInput'>Email</label>
                <input type='email' 
                    className='form-control'
                    id='emailInput'
                    placeholder='Your account email'
                    ref={emailRef}
                    onKeyPress={onKeyPress}
                    autoComplete='off' />
            </div>
            <div className='form-group form-row'>
                <label htmlFor='emailInput'>Password</label>
                <input type='password' 
                    className='form-control'
                    id='passwordInput'
                    ref={passwordRef}
                    onKeyPress={onKeyPress}
                    autoComplete='off' />
            </div>

            {
                errorMessage ? (
                    <div className='form-group row'>
                        <span className='col-12 text-danger'>{errorMessage}</span>
                    </div>
                ) : null
            }

            <FormActions isBusy={isBusy}
                actionText='Delete'
                action={requestDelete}
                actionClassName='btn btn-danger'
                cancel={cancel} />
        </div>
    );
}