import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { RELEASE } from '../constants';
import { toggleEditDialog, toggleReleaseFlyout } from '../actions';
import { getCollections } from 'data/selectors';
import Loading from 'common/components/Loading';

export default function Release(props) {
    const dispatch = useDispatch();
    const firestore = useFirestore();

    const [isCollapsed, setIsCollapsed] = useState(!!props.isCollapsed);
    const [isLoading, setIsLoading] = useState(false);
    const [prevStatus, setPrevStatus] = useState(null);

    const collections = useSelector(getCollections, shallowEqual);

    const collapseClassName = classNames('storymap-collapse', {
        'storymap-collapsed': isCollapsed
    });

    useEffect(() => {
        if (!props.release?.id || prevStatus === props.release.status) {
            return;
        }

        setPrevStatus(props.release.status);

        if (props.release.status === 1 && !isCollapsed) {
            setIsCollapsed(true);
        }
    }, [props.release]);

    function edit(e) {
        e.preventDefault();

        dispatch(toggleEditDialog(RELEASE, props.release));
    }

    function preview(e) {
        e.preventDefault();
``
        dispatch(toggleReleaseFlyout(props.release));
    }

    async function destroy(e) {
        e.preventDefault();

        if (!collections || !props.release.id) {
            return;
        }

        let children = (collections.tasks || []).filter(t => t.releaseId === props.release.id);

        if (children && children.length) {
            if (children.length > 100) {
                return alert('WOAH! That\'s a lot of data you\'re trying to delete there. Try another way for now, or contact support.');
            }

            if (!window.confirm(`Performing this delete will delete the current release and associated tasks. Continue?`)) {
                return;
            }

            await setIsLoading(true);

            let batch = firestore.batch();

            for (let i = 0, len = children.length; i < len; i++) {
                const child = children[i];
                await firestore.collection('tasks').doc(child.id).delete();
            }

            await batch.commit();
        }
        else {
            await setIsLoading(true);
        }

        await firestore.collection('releases').doc(props.release.id).delete();

        await setIsLoading(true);
    }

    function collapse() {
        setIsCollapsed(!isCollapsed);
    }

    // This state occurs, oddly, when deleting a release from a map with >2 releases
    if (!props.release || !props.release.id || props.release.order == null) {
        return null;
    }

    return (
        <Draggable draggableId={props.release.id} index={props.index} isDragDisabled={!props.isEditModeEnabled}>
            {provided => (
                <fieldset className='storymap-release'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <legend onClick={collapse} className='clickable'>
                        <FontAwesomeIcon icon={['fas', 'caret-down']} className={collapseClassName} />
                        {props.release?.status !== 1 ? props.release.name : <del>{props.release.name}</del>}
                    </legend>

                    { isCollapsed || isLoading ? null : (
                        <div className='d-flex flex-row'>
                            <div className='storymap-actions-container'>
                                { props.isEditModeEnabled ? (
                                    <a href='#' className='btn btn-primary btn-circle mr-2' title='Edit' onClick={edit}>
                                        <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                    </a>
                                ) : null }

                                <a href='#' className='btn btn-primary btn-circle mr-2' title='Preview Release' onClick={preview}>
                                    <FontAwesomeIcon icon={['fas', 'search']}/>
                                </a>

                                { props.isEditModeEnabled ? (
                                    <a href='#' className='btn btn-danger btn-circle' title='Delete' onClick={destroy}>
                                        <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                    </a>
                                ) : null }
                            </div>

                            {props.children}
                        </div>
                    )}

                    { isLoading ? <Loading /> : null}
                </fieldset>
            )}
        </Draggable>
    );
};