import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import { getEditCardState } from 'data/selectors';
import { toggleEditDialog, updateCard } from '../actions';
import Modal from 'common/components/Modal';
import FormActions from 'common/components/FormActions';
import useKeyPress from 'common/hooks/useKeyPress';
import * as Constants from '../constants';
import LabelEditor from './LabelEditor';

export default function EditCard({ project }) {
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const nameRef = useRef(null);

    const appState = useSelector(getEditCardState);
    const [isBusy, setIsBusy] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    useKeyPress('Escape', {
        onKeyUp: () => {
            // Only toggle closed
            if (appState.isEditCardDialogOpen) {
                dispatch(toggleEditDialog());
            }
        }
    });

    /* Note that the defaultValues are only used on load, the below useEffect
       handles resetting the defaultValues as new cards are created/edited.
       This is all a hack around the fact that the modal should really be 
       recreated each time its opened.
    */
    const { formState, handleSubmit, register, reset } = useForm({
        defaultValues: {
            name: appState.editCard?.name || '',
            description: appState.editCard?.description || ''
        }
    });

    useEffect(() => {
        if (appState.isEditCardDialogOpen) {
            nameRef.current.scrollIntoView(false);

            reset({
                name: appState.editCard?.name || '',
                description: appState.editCard?.description || ''
            });

            setIsComplete(appState.editCard?.status === 1);
        }
    }, [appState.isEditCardDialogOpen]);

    useEffect(() => {
        if (isComplete !== (appState.editCard?.status === 1)) {
            handleSubmit(save)();
        }
    }, [isComplete])

    function getNextOrderByType(type, card) {
        let filteredCollection = [];

        switch (type) {
            case Constants.GOAL:
            case Constants.RELEASE:
                filteredCollection = (appState.collection || []);
                break;
            case Constants.STORY:
                filteredCollection = (appState.collection || [])
                    .filter(s => s.goalId === card.goalId);
                break;
            case Constants.TASK:
                filteredCollection = (appState.collection || [])
                    .filter(t => t.storyId === card.storyId && t.releaseId === card.releaseId);
                break;
        }

        return filteredCollection.length;
    }

    async function saveCard(type, card) {
        if (!card.id) {
            card.order = getNextOrderByType(type, card);
            await firestore.collection(appState.collectionName).add(card);
        }
        else {
            const id = card.id;
            delete card.id;
            await firestore.collection(appState.collectionName).doc(id).set(card);
        }
    }

    async function save(values) {
        let { 
            editCardType,
            editCard
        } = appState;

        await setIsBusy(true);

        await saveCard(editCardType, {
            ...editCard,
            ...values,
            status: isComplete ? 1 : 0,
            teamId: project.teamId,
            projectId: project.id
        });

        await setIsBusy(false);

        await dispatch(toggleEditDialog());
    }

    function cancel() {
        dispatch(toggleEditDialog());
    }

    function onToggleLabel(colorName) {
        let update = {
            ...appState.editCard
        };

        let colors = update.colors ? [...update.colors] : [];

        if (colors.includes(colorName)) {
            colors = colors.filter(c => c !== colorName);
        }
        else {
            colors.push(colorName);
        }

        update.colors = colors;

        dispatch(updateCard(update));
    }

    function toggleComplete() {
        setIsComplete(!isComplete);
    }

    const formClassName = classNames('needs-validation', {
        'was-validated': formState.isSubmitted
    });

    const cardColors = appState.editCard?.colors || [];

    const secondaryActions = [
        {
            action: toggleComplete,
            actionText: appState.editCard?.status !== 1 ? 'Mark Complete' :
                'Reopen'
        }
    ];

    return (
        <Modal isOpen={appState.isEditCardDialogOpen}>
            <form className={formClassName} onSubmit={handleSubmit(save)}>
                <div className='d-flex storymap-card-editor'>
                    <div>
                        <div className='form-group form-row'>
                            <label htmlFor='nameInput'>Card Name</label>
                            <input type='text' 
                                autoFocus
                                required
                                className='form-control'
                                id='nameInput'
                                placeholder='Enter a name for the card to be created'
                                ref={(e) => {
                                    register(e, { required: 'Required' });
                                    // Need to share the ref to focus on big storymaps
                                    nameRef.current = e;
                                }}
                                name='name'
                                disabled={!appState.isEditModeEnabled || formState.isSubmitting} />
                        </div>

                        <div className='form-group form-row'>
                            <label htmlFor='descriptionInput'>Description</label>
                            <textarea className='form-control'
                                id='descriptionInput'
                                name='description'
                                ref={register()}
                                rows={10}
                                disabled={!appState.isEditModeEnabled || formState.isSubmitting} >
                            </textarea>
                        </div>
                    </div>

                    { appState.editCardType !== Constants.RELEASE ? (
                        <div>
                            <div className='form-group'>
                                <label>Labels</label>

                                {Constants.COLORS.map(color => (
                                    <LabelEditor key={`editcard-${color.colorName}`}
                                        isSelected={cardColors.includes(color.colorName)}
                                        onToggleLabel={onToggleLabel}
                                        isSelectable={true}
                                        isEditModeEnabled={appState.isEditModeEnabled}
                                        {...color} />
                                ))}
                            </div>
                            
                        </div>
                    ) : null }
                </div>

                { appState.isEditModeEnabled ? (
                    <FormActions isBusy={isBusy}
                        actionText={appState.editCard?.id ? 'Save' : 'Add'}
                        action={handleSubmit(save)}
                        secondaryActions={secondaryActions}
                        cancel={cancel} />
                ) : (
                    <div className='position-relative'>
                        <div>
                            <button type='submit' className='btn btn-primary' onClick={cancel}>Close</button>
                        </div>
                    </div>
                ) }
            </form>
        </Modal>
    );
}