import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import { toggleEditProjectDialog } from '../actions'
import { getProjects } from 'data/selectors';
import Page from 'common/components/Page';
import CreateProject from './CreateProject';
import ProjectListItem from './ProjectListItem';

export default function ProjectsLayout() {
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const dispatch = useDispatch();

    const user = useSelector(({ auth: { user } }) => user);
    useFirestoreConnect([
        {
            collection: 'projects',
            where: [
                ['teamId', '==', user.teamId]
            ],
            orderBy: ['name']
        }
    ]);
    const projects = useSelector(getProjects);

    if (!isLoaded(projects)) {
        return <Page title='Projects' isLoading={true} />;
    }

    function createProject(e) {
        e.preventDefault();

        dispatch(toggleEditProjectDialog());
    }

    return (
        <Page title='Projects' className='px-4 d-flex'>

            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th>
                            <button className='btn btn-primary' onClick={createProject}>+ New Project</button>
                        </th>
                        <th className='text-right'>
                            <input type='text'
                                className='form-control d-inline w-auto'
                                placeholder='Search...'
                                autoFocus
                                value={searchTerm}
                                onChange={handleChange} />
                        </th>
                    </tr>
                    <tr>
                        <th scope='col'>Name</th>
                        <th scope='col'>Public</th>
                    </tr>
                </thead>
                <tbody>
                    { (projects || [])
                        .filter(project => {
                            return (project.name.toLowerCase().includes(searchTerm.toLowerCase()));
                        })
                        .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }))
                        .map(project => <ProjectListItem key={project.id} data={project} />)}
                </tbody>
            </table>

            <CreateProject />
        </Page>
    );
}