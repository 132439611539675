import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useForm } from 'react-hook-form';
import { Link, Redirect, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import Page from 'common/components/Page';
import { setAuthLoaded, setUser } from '../actions';
import { getAuthExists } from 'data/selectors';

export default function Register() {
    const firebase = useFirebase();
    const firestore = useFirestore();
    const dispatch = useDispatch();
    let history = useHistory();
    const { handleSubmit, register, setValue, watch, errors, formState } = useForm({
        defaultValues: {
            teamType: 'new'
        }
    });

    const authExists = useSelector(getAuthExists);
    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState(null);
    const teamType = watch('teamType');
    const previousTeamType = useRef(null);

    useEffect(() => {
        if (teamType !== previousTeamType.current &&
            teamType === 'new') {
            setValue('teamName', '');
        }
    }, [teamType]);

    if (authExists) {
        let location = window.location.search.split('=')[1] || '/projects';
        return <Redirect to={location} />;
    }

    async function completeRegistration(values) {
        setIsBusy(true);

        // TODO: this should all be in a transaction

        try {
            await dispatch(setAuthLoaded(false));

            let createResult = await firebase.createUser({
                email: values.email,
                password: values.password
            });

            if (values.teamType === 'new') {
                const teamCreateResult = await firestore.collection('teams').add({
                    teamName: values.teamName,
                    creator: createResult.user.uid
                });

                await firestore.collection('profiles').doc(createResult.user.uid).set({
                    name: values.friendlyName,
                    email: values.email,
                    teamId: teamCreateResult.id
                });

                await dispatch(setUser({
                    displayName: values.friendlyName,
                    teamId: teamCreateResult.id,
                    team: {
                        teamName: values.teamName
                    }
                }));
            }
            else { // existing
                const result = await firestore.collection('teams')
                    .where('teamName', '==', values.teamName)
                    .limit(1)
                    .get();

                await firestore.collection('requests').add({
                    name: values.friendlyName,
                    email: values.email,
                    teamId: result.docs[0].id,
                    type: 'team',
                    uid: createResult.user.uid
                });

                await firestore.collection('profiles').doc(createResult.user.uid).set({
                    name: values.friendlyName,
                    email: values.email,
                    teamId: null
                });

                await dispatch(setUser({
                    displayName: values.friendlyName,
                    teamId: null,
                    team: {}
                }));
            }

            // TODO: handle users that haven't been approved
            history.push('/projects');
        }
        catch(e) {
            setError(e && e.message ? e.message : 'Unknown error.');
            setIsBusy(false);
        }
    }

    async function validateTeamName(teamName) {
        const result = await firestore.collection('teams')
            .where('teamName', '==', teamName)
            .limit(1)
            .get();

        if (teamType === 'new') {
            return result && result.empty ? true : 'Selected team name already exists. Please choose another, or select "Join an Existing Team".';
        }
        else { // existing
            return result && !result.empty ? true : 'Selected team name does not exist. Please choose another, or select "Create a New Team".';
        }
    }

    const formClassName = classNames('needs-validation', {
        'was-validated': formState.isSubmitted
    });
    const labelClassName = 'col-5 col-sm-4 col-md-3 col-lg-2 col-form-label';
    const inputClassName = 'form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10';
    const checkClassName = 'col-7 col-sm-8 col-md-9 col-lg-10 offset-5 offset-sm-4 offset-md-3 offset-lg-2 form-check';
    const helpClassName = 'form-text text-muted col-7 col-sm-8 col-md-9 col-lg-10 offset-5 offset-sm-4 offset-md-3 offset-lg-2';
    const invalidClassName = 'col-7 col-sm-8 col-md-9 col-lg-10 offset-5 offset-sm-4 offset-md-3 offset-lg-2 invalid-feedback';

    const teamNameClassName = classNames(inputClassName, {
        'is-valid': formState.isSubmitted && !errors.teamName,
        'is-invalid': formState.isSubmitted && errors.teamName
    });

    return (
        <Page title='Register' className='row mb-4 justify-content-center'>
            <div className='col-12 col-md-8 col-lg-5'>
                <div className='card'>
                    <div className='card-header'>
                        <h2>Register</h2>
                    </div>

                    <div className='card-body mt-4'>
                        <form onSubmit={handleSubmit(completeRegistration)} className={formClassName} noValidate>
                            <div className='form-group form-row'>
                                <label htmlFor='friendlyName' className={labelClassName}>Name</label>
                                <input type='text' className={inputClassName} id='friendlyName' name='friendlyName' autoFocus required disabled={isBusy} ref={register({ required: 'Required' })} />
                                <small className={helpClassName}>Whatever you'd like us to call you. Be nice to yourself.</small>
                            </div>
                            <div className='form-group form-row'>
                                <label htmlFor='email' className={labelClassName}>Email</label>
                                <input type='email' className={inputClassName} id='email' name='email' required disabled={isBusy} ref={register({ required: 'Required' })} />
                            </div>
                            <div className='form-group form-row'>
                                <label htmlFor='password' className={labelClassName}>Password</label>
                                <input type='password' className={inputClassName} id='password' name='password' required disabled={isBusy} ref={register({ required: 'Required' })} />
                            </div>
                            <div className='form-group'>
                                <div className={checkClassName}>
                                    <input className='form-check-input' type='radio' name='teamType' id='newTeam' value='new' disabled={isBusy} ref={register({ required: 'Required' })} />
                                    <label className='form-check-label' htmlFor='newTeam'>
                                        Create a New Team
                                    </label>
                                </div>
                                <div className={checkClassName}>
                                    <input className='form-check-input' type='radio' name='teamType' id='existingTeam' value='existing' disabled={isBusy} ref={register({ required: 'Required' })} />
                                    <label className='form-check-label' htmlFor='existingTeam'>
                                        Join an Existing Team
                                    </label>
                                </div>
                            </div>
                            <div className='form-group form-row'>
                                <label htmlFor='teamName' className={labelClassName}>Team Name</label>
                                <input type='text' className={teamNameClassName} id='teamName' name='teamName' required disabled={isBusy || teamType === 'new'} ref={register({ validate: validateTeamName })} />
                                <div className={invalidClassName}>{errors?.teamName?.message}</div>
                            </div>

                            {
                                error ? (
                                    <div className='form-group row'>
                                        <span className='col-sm-12 text-danger'>{error}</span>
                                    </div>
                                ) : null
                            }

                            <hr />

                            <div className='position-relative'>
                                <button className='btn btn-primary' type='submit' disabled={isBusy || formState.isSubmitting}>
                                    {isBusy || formState.isSubmitting ?
                                        <span className='spinner-border spinner-border-sm mr-1'></span> : null}
                                    {isBusy || formState.isSubmitting ?
                                        'Working on it...' : 'Register'}
                                </button>
                                &nbsp;or&nbsp;<Link to='/login'>login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Page>       
    );
}