import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from 'common/components/Loading';
import { getCurrentProject } from 'data/selectors';

export default function LabelEditor(props) {
    const firestore = useFirestore();
    
    const inputRef = useRef(null);
    const project = useSelector(getCurrentProject);

    const [isEditing, setIsEditing] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    const isSelectable = !!props.isSelectable && props.isEditModeEnabled;

    function toggle(e) {
        e.preventDefault();

        if (!isSelectable) {
            return;
        }

        props.onToggleLabel(props.colorName);
    }

    async function editProject() {
        await setIsBusy(true);

        const update = {
            ...project,
            colors: project.colors ? { ...project.colors } : {}
        };

        update.colors[props.colorName] = inputRef.current.value;

        const id = update.id;
        delete update.id;
        await firestore.collection('projects').doc(id).set(update);

        await setIsBusy(false);
        await setIsEditing(false);
    }

    async function toggleEdit() {
        if (isEditing) {
            return editProject();
        }

        await setIsEditing(true);

        inputRef.current.focus();
    }

    function getLabelText() {
        if (!project) {
            return '';
        }

        if (!project || !project.colors) {
            return;
        }

        return project.colors[props.colorName] || '';
    }

    function onKeyPress(e) {
        if (e.key === 'Enter') {
            editProject();
        }
    }

    return (
        <div className='input-group mb-3'>
            <div className='input-group-prepend'>
                <a href='#' className='input-group-text storymap-label-selector'
                    style={{ backgroundColor: props.color, borderColor: props.color }}
                    onClick={toggle}>
                    { isSelectable ? <FontAwesomeIcon icon={['fas', props.isSelected ? 'check' : 'plus']} /> : null }
                </a>
            </div>

            <input type='text'
                className='form-control'
                defaultValue={getLabelText()}
                disabled={!isEditing && !isBusy}
                style={{ borderColor: props.color }}
                ref={inputRef}
                onKeyPress={onKeyPress} />

            { isBusy ? (
                <div className='storymap-label-edit'>
                    <Loading />
                </div>
            ) : props.isEditModeEnabled ? (
                <a href='#' className='storymap-label-edit' onClick={toggleEdit} title='Edit Label'>
                    <FontAwesomeIcon icon={['fas', isEditing ? 'save' : 'pencil-alt']} />
                </a>
            ) : null }
        </div>
    );
};