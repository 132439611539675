import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { toggleDeleteProjectDialog } from '../actions';
import { getCollections } from 'data/selectors';
import FormActions from 'common/components/FormActions';
import useKeyPress from 'common/hooks/useKeyPress';

export default function DeleteProject({ project }) {
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const history = useHistory();
    const nameRef = useRef(null);

    const collections = useSelector(getCollections, shallowEqual);
    const moduleState = useSelector(({ storymap }) => storymap);
    useKeyPress('Escape', {
        onKeyUp: () => {
            // Only toggle closed
            if (moduleState.isDeleteProjectDialogOpen) {
                dispatch(toggleDeleteProjectDialog());
            }
        }
    });

    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        nameRef.current.focus();
    }, [moduleState.isDeleteProjectDialogOpen])

    async function requestDelete(e) {
        e && e.preventDefault();

        if (nameRef.current.value !== project.name) {
            return;
        }

        let children = [];

        ['goals', 'stories', 'tasks', 'releases'].forEach(collectionName => {
            (collections[collectionName] || []).forEach(({ id }) => children.push({
                collectionName,
                id
            }));
        });

        if (children && children.length) {
            if (children.length > 200) {
                return alert('WOAH! That\'s a lot of data you\'re trying to delete there. Try another way for now, or contact support.');
            }

            if (!window.confirm(`Performing this delete will delete the current project, including all your goals, stories, tasks, etc. Continue?`)) {
                return;
            }

            await setIsBusy(true);

            let batch = firestore.batch();

            for (let i = 0, len = children.length; i < len; i++) {
                const child = children[i];
                await firestore.collection(child.collectionName).doc(child.id).delete();
            }

            await batch.commit();
        }
        else {
            await setIsBusy(true);
        }

        await firestore.collection('projects').doc(project.id).delete();

        await setIsBusy(false);
        await dispatch(toggleDeleteProjectDialog());

        history.push('/projects');
    }

    function cancel() {
        nameRef.current.value = '';

        dispatch(toggleDeleteProjectDialog());
    }

    function onKeyPress(e) {
        if (e.key === 'Enter') {
            requestDelete();
        }
    }

    const classes = classNames('modal', {
        'modal-open': moduleState.isDeleteProjectDialogOpen
    });

    return (
        <div className={classes}>
            <div className='form-group form-row'>
                <label htmlFor='projectNameInput'>Project Name</label>
                <input type='text' 
                    className='form-control'
                    id='projectNameInput'
                    placeholder='Enter project name to confirm deletion'
                    ref={nameRef}
                    defaultValue={moduleState.project ? moduleState.project.name : ''}
                    onKeyPress={onKeyPress}
                    autoComplete='off' />
            </div>

            <FormActions isBusy={isBusy}
                actionText='Delete'
                action={requestDelete}
                actionClassName='btn btn-danger'
                cancel={cancel} />
        </div>
    );
}