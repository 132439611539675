import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import {
    getAuthExists,
    getCurrentProject,
    getFilteredGoals,
    getIsStoryMapEditEnabled,
    getFilteredStories,
    getFilteredReleases,
    getFilteredTasks
} from 'data/selectors';

import { preemptFirestoreUpdate, toggleBusy } from 'modules/app/actions';
import {
    toggleEditProjectDialog,
    toggleDeleteProjectDialog
} from '../actions';

import { default as Component } from '../components/StoryMapLayout';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        preemptFirestoreUpdate,
        toggleBusy,
        toggleEditProjectDialog,
        toggleDeleteProjectDialog
    }, dispatch);
}

export default compose(
    withRouter,
    firestoreConnect(({ match }) => {
        return [
            {
                collection: 'projects',
                doc: match.params.id,
                storeAs: 'currentProject'
            },
            {
                collection: 'goals',
                where: [
                    ['projectId', '==', match.params.id],
                    ['teamId', '==', match.params.teamId]
                ],
                orderBy: 'order'
            },
            {
                collection: 'stories',
                where: [
                    ['projectId', '==', match.params.id],
                    ['teamId', '==', match.params.teamId]
                ],
                orderBy: 'order'
            },
            {
                collection: 'releases',
                where: [
                    ['projectId', '==', match.params.id],
                    ['teamId', '==', match.params.teamId]
                ],
                orderBy: 'order'
            },
            {
                collection: 'tasks',
                where: [
                    ['projectId', '==', match.params.id],
                    ['teamId', '==', match.params.teamId]
                ],
                orderBy: 'order'
            }
        ];
    }),
    connect((state) => {
        const isAuthenticated = getAuthExists(state);
        const project = getCurrentProject(state);

        return {
            project,
            goals: getFilteredGoals(state),
            stories: getFilteredStories(state),
            releases: getFilteredReleases(state),
            tasks: getFilteredTasks(state),
            isEditModeEnabled: getIsStoryMapEditEnabled(state) && (isAuthenticated || project.isPublic),
            collapsedGoals: state.storymap.collapsedGoals,
            isAuthenticated,
            errors: state.firestore.errors
        };
    }, mapDispatchToProps)
)(Component);