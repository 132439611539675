import React from 'react';
import { useDispatch } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import { toggleBusy } from 'modules/app/actions';
import { toggleEditMode, toggleClosed } from '../actions';

export default function StoryMapActions(props) {
    const dispatch = useDispatch();
    const firestore = useFirestore();

    async function toggleShowClosed(e) {
        await dispatch(toggleClosed());
    }
    
    async function toggleEdit(e) {
        await dispatch(toggleEditMode());
    }

    async function togglePublic(e) {
        await dispatch(toggleBusy());

        await firestore.collection('projects').doc(props.project.id).set({
            ...props.project,
            isPublic: !props.project.isPublic
        });

        await dispatch(toggleBusy());
    }

    return (
        <div className='storymap-actions'>
            <div className='storymap-action'>
                <label htmlFor='closedToggle'>Show Closed</label>
                <input type='checkbox' className='toggle' id='closedToggle' checked={props.showClosed} onChange={toggleShowClosed} />
            </div>

            { props.isAuthenticated || props.project.isPublic ? (
                <div className='storymap-action'>
                    <label htmlFor='editModeToggle'>Edit Mode</label>
                    <input type='checkbox' className='toggle' id='editModeToggle' checked={props.isEditModeEnabled} onChange={toggleEdit} />
                </div>
            ) : null }

            { props.isAuthenticated ? (
                <div className='storymap-action'>
                    <label htmlFor='isPublicToggle'>Public</label>
                    <input type='checkbox' className='toggle' id='isPublicToggle' checked={props.project.isPublic} onChange={togglePublic} />
                </div>
            ) : null }
        </div>
    );
}