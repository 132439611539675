import React from 'react';
import classNames from 'classnames';

export default function Modal({ children, isOpen, small }) {
    const classes = classNames('modal', {
        'modal-open': isOpen,
        'modal-small': small
    });

    return (
        <div className={classes}>
            {isOpen ? children : null}
        </div>
    );
}