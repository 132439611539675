import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ProjectListItem extends Component {
    edit = (e) => {
        e.preventDefault();
    }

    destroy = (e) => {
        e.preventDefault();
    }

    viewProject = (e) => {
        if (['a', 'path'].includes(e.target.nodeName)) {
            return;
        }

        const { data, history } = this.props;

        history.push(`/project/${data.teamId}/${data.id}`);
    }

    render() {
        let { data } = this.props;

        return (
            <tr className='clickable' onClick={this.viewProject}>
                <th>{data.name}</th>
                <td>{data.isPublic ? 'Yes' : 'No'}</td>
            </tr>
        );
    }
}

export default withRouter(ProjectListItem);