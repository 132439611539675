import React, { Component } from 'react';
import { isLoaded } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';

import * as Constants from '../constants';
import Loading from 'common/components/Loading';
import People from './People';

class TeamLayout extends Component {
    render() {
        const {
            team,
            profiles,
            requests
        } = this.props;

        if (!isLoaded(team, profiles, requests)) {
            return <Loading />;
        }

        debugger;

        return (
            <main className='container container-fluid app-body'>
                <div className='mb-4'>
                    <Helmet>
                        <title>Fable - Team</title>
                    </Helmet>

                    <header className='row mt-4 justify-content-center'>
                        <div className='col-12'>
                            <h1><span className='text-muted'>Team: </span>{team.teamName}</h1>
                        </div>
                    </header>
                    
                    <article className='mt-4'>
                        <h4>Members</h4>
                        <People people={profiles} type={Constants.TYPE_MEMBER} />
                    </article>

                    {requests && requests.length ? (
                        <article className='mt-4'>
                            <h4>Requests</h4>
                            <People people={requests} type={Constants.TYPE_REQUEST} />
                        </article>
                    ) : null}
                </div>
            </main>
        );
    }
}

export default TeamLayout;