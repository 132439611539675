import React from 'react';
import { render } from 'react-dom';

import './stylesheets/main.scss';
import 'utilities/fontawesome';

import { App } from './modules/app';

if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });
}

Honeybadger.configure({
    apiKey: '30ca6fa3',
    environment: process.env.NODE_ENV
});

render(
    <App />,
    document.getElementById('app')
);