import React from 'react';

function preventDefault(cb) {
    return function _preventDefaultThenRunCallback(e) {
        e.preventDefault();
        cb(e);
    };
}

export default ({
    action,
    actionClassName = 'btn btn-primary',
    actionText,
    cancel,
    isBusy,
    secondaryActions
}) => (
    <div className='position-relative'>
        <button className={actionClassName} type='submit' disabled={isBusy} onClick={preventDefault(action)}>
            {isBusy ? <span className='spinner-border spinner-border-sm mr-1'></span> : null}
            {isBusy ? 'Working...' : actionText ? actionText : 'Save'}
        </button>
        {
            secondaryActions?.length ? secondaryActions.map((sAction, index) => (
                <button className={sAction?.className || 'btn btn-secondary ml-3'}
                    type='submit'
                    disabled={isBusy}
                    onClick={preventDefault(sAction.action)}
                    key={index}>
                    {isBusy ? <span className='spinner-border spinner-border-sm mr-1'></span> : null}
                    {isBusy ? 'Working...' : sAction.actionText}
                </button>
            )) : null
        }
        <a href='#' className='ml-3' onClick={preventDefault(cancel)}>Cancel</a>
    </div>
);