import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import { toggleEditProjectDialog } from '../actions';
import * as Constants from '../constants';
import LabelEditor from './LabelEditor';
import FormActions from 'common/components/FormActions';
import Modal from 'common/components/Modal';
import useKeyPress from 'common/hooks/useKeyPress';

export default function EditProject({ isEditModeEnabled, project }) {
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const nameRef = useRef(null);

    const moduleState = useSelector(({ storymap }) => storymap);

    const [isBusy, setIsBusy] = useState(false);
    useKeyPress('Escape', {
        onKeyUp: () => {
            // Only run for close
            if (moduleState.isEditProjectDialogOpen) {
                dispatch(toggleEditProjectDialog());
            }
        }
    })

    useEffect(() => {
        if (moduleState.isEditProjectDialogOpen) {
            nameRef.current?.focus();
            nameRef.current?.scrollIntoView();
            nameRef.current.value = project.name;
        }
    }, [moduleState.isEditProjectDialogOpen])

    async function save() {
        const name = nameRef.current.value;

        if (!name) {
            return;
        }

        const update = {
            ...project,
            name
        };

        await setIsBusy(true);

        await firestore.collection('projects').doc(project.id).set(update);

        await dispatch(toggleEditProjectDialog());
        await setIsBusy(false);
    }

    function cancel() {
        nameRef.current.value = '';

        dispatch(toggleEditProjectDialog());
    }

    function onKeyPress(e) {
        if (e.key === 'Enter') {
            save();
        }
    }

    return (
        <Modal isOpen={moduleState.isEditProjectDialogOpen}>
            <div className='d-flex storymap-card-editor align-items-start'>
                <div className='form-group form-row'>
                    <label htmlFor='projectNameInput'>Project Name</label>
                    <input type='text' 
                        className='form-control'
                        id='projectNameInput'
                        placeholder='Enter a name for your project'
                        ref={nameRef}
                        disabled={!isEditModeEnabled}
                        defaultValue={moduleState.project ? moduleState.project.name : ''}
                        onKeyPress={onKeyPress} />
                </div>

                <div>
                    <div className='form-group'>
                        <label>Labels</label>
                        {Constants.COLORS.map(color => (
                            <LabelEditor key={`editlabels-${color.colorName}`}
                                isSelectable={false}
                                isEditModeEnabled={isEditModeEnabled}
                                {...color} />
                        ))}
                    </div>
                </div>
            </div>

            { isEditModeEnabled ? (
                <FormActions isBusy={isBusy}
                    actionText='Save'
                    action={save}
                    cancel={cancel} />
            ) : (
                <div className='position-relative'>
                    <div>
                        <button type='submit' className='btn btn-primary' onClick={cancel}>Close</button>
                    </div>
                </div>
            ) }
        </Modal>
    );
}