import * as t from './actionTypes';

export function toggleEditDialog(cardType, card) {
    return {
        type: t.TOGGLE_EDIT_DIALOG,
        cardType,
        card
    };
}

export function updateCard(card) {
    return {
        type: t.UPDATE_CARD,
        card
    };
}

export function toggleDeleteProjectDialog() {
    return {
        type: t.TOGGLE_DELETE_PROJECT_DIALOG
    };
}

export function toggleEditProjectDialog() {
    return {
        type: t.TOGGLE_EDIT_PROJECT_DIALOG
    };
}

export function toggleReleaseFlyout(release = null) {
    return {
        type: t.TOGGLE_RELEASE_FLYOUT,
        release
    };
}

export function toggleEditMode() {
    return {
        type: t.TOGGLE_EDIT_MODE
    };
}

export function toggleGoal(goalId) {
    return {
        type: t.TOGGLE_GOAL,
        goalId
    };
}

export function toggleClosed() {
    return {
        type: t.TOGGLE_SHOW_CLOSED
    };
}

export function reset() {
    return {
        type: t.RESET_STORYMAP
    };
}