import React from 'react';
import { useDispatch } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import { TASK } from '../constants';
import { toggleEditDialog } from '../actions';

export default function TaskContainer(props) {
    const dispatch = useDispatch();

    const addNewClassName = classNames('storymap-card', {
        'storymap-card-sm-for-column': props.children && props.children.length > 0,
        'storymap-card-create delinkify': props.isEditModeEnabled
    });

    function edit(e) {
        e.preventDefault();

        dispatch(toggleEditDialog(TASK, {
            releaseId: props.releaseId,
            storyId: props.storyId
        }));
    }

    return (
        <Droppable droppableId={`${props.releaseId}-${props.storyId}`} type={ TASK }>
            {(provided, snapshot) => (
                <div ref={provided.innerRef}
                    style={{ border: snapshot.isDraggingOver ? '1px dashed #ccc' : 'initial' }}
                    {...provided.droppableProps}
                >
                    {props.children}
                    {provided.placeholder}
                    
                    { props.isEditModeEnabled ? 
                        <a href='#' className={addNewClassName} onClick={edit}>+</a> : 
                        <div className={addNewClassName}></div> }
                </div>
            )}
        </Droppable>
    );
};