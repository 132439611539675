import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultMemoize } from 'reselect';
import classNames from 'classnames';

import { getAuthExists, getGoals, getStories, getTasks } from 'data/selectors';
import { toggleReleaseFlyout } from '../actions';
import * as Constants from '../constants';
import Card from './Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ReleaseFlyout() {
    const dispatch = useDispatch();

    const moduleState = useSelector(({ storymap }) => storymap);
    const authExists = useSelector(getAuthExists);
    const allGoals = useSelector(getGoals);
    const allStories = useSelector(getStories);
    const allTasks = useSelector(getTasks);

    const getReleaseTasks = defaultMemoize((releaseId, goals, stories, tasks) => {
        return (tasks || []).filter(t => t.releaseId === releaseId).map(task => {
            const story = stories.find(s => s.id === task.storyId);

            return {
                ...task,
                story: {
                    ...story,
                    goal: {
                        ...goals.find(g => g.id === story.goalId)
                    }
                }
            };
        }).sort((a, b) => a.story.goal.order - b.story.goal.order || a.story.order - b.story.order || a.order - b.order);
    });

    function close(e) {
        e.preventDefault();

        dispatch(toggleReleaseFlyout());
    }

    const classes = classNames('flyout', {
        'flyout-open': moduleState.isReleaseFlyoutOpen
    });

    return (
        <div className={classes}>
            <h4>{moduleState.release?.name}</h4>

            <a href='#' className='flyout-close delinkify' onClick={close}>
                <FontAwesomeIcon icon={['fas', 'times']} />
            </a>

            <div className='d-flex flex-wrap align-items-center'>
                {moduleState.release != null ? getReleaseTasks(moduleState.release.id, allGoals, allStories, allTasks).map(task => (
                    <Card type={Constants.TASK} card={task} isEditModeEnabled={moduleState.isEditModeEnabled && authExists} />
                )) : null}
            </div>
        </div>
    );
}