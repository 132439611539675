import React, { useState } from 'react';

import Page from 'common/components/Page';
import EditProfile from './EditProfile';
import DeleteAccount from './DeleteAccount';

export default function ProfileLayout() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Page title='Profile' className='d-flex justify-content-center'>
            <div className='col-12 col-md-8 col-lg-7'>
                <div className='card'>
                    <div className='card-header'>
                        <h2>Profile</h2>
                    </div>

                    <div className='card-body'>
                        <EditProfile setIsModalOpen={setIsModalOpen} />
                    </div>
                </div>
            </div>

            <DeleteAccount isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
        </Page>
    );
}