import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect,  useLocation } from 'react-router-dom';

import Loading from 'common/components/Loading';

export default ({ children }) => {
    const location = useLocation();

    const auth = useSelector(({ auth }) => auth);

    if (auth && !auth.isLoaded) {
        return <Loading />;
    }

    if (auth && auth.isLoaded && (!auth.user || !auth.user.uid)) {
        return location.pathname === '/approval-required' ? <Redirect to='/login' /> :
            <Redirect to={`/login?redirect=${location.pathname}`} />;
    }
    else if (auth && auth.isLoaded && !auth.user.teamId && location.pathname !== '/approval-required') {
        return <Redirect to='/approval-required' />;
    }

    return children;
};