import { createSelector } from 'reselect';
import { isLoaded } from 'react-redux-firebase';
import * as CardTypes from '../modules/storymap/constants';

const NOT_LOADED = { isLoaded: false };

const getFirestoreData = (state) => {
    if (!state.firestore || !state.firestore.data) {
        return NOT_LOADED;
    }

    return state.firestore.data;
};

const getFirestoreOrderedData = (state) => {
    if (!state.firestore || !state.firestore.ordered) {
        return NOT_LOADED;
    }

    return state.firestore.ordered;
};

const getOptimisticData = (state) => {
    if (!state.firestore || !state.firestore.data || !state.mirror) {
        return NOT_LOADED;
    }

    //return state.firestore.data;
    return state.mirror;
}

export const getStoryMap = (state) => {
    if (!state || !state.storymap) {
        return {};
    }

    return state.storymap;
};

function arrayify(firestoreData, doSort = false) {
    if (!firestoreData) {
        return firestoreData;
    }

    let result = [];
    for (var id in firestoreData) {
        if (!firestoreData[id]) {
            continue;
        }
        result.push({
            id: id,
            ...firestoreData[id]
        });
    }

    if (doSort) {
        return result.sort((a, b) => a.order - b.order);
    }

    return result;
}

export const getProjects = createSelector(
    [ getFirestoreData ],
    (data) => {
        if (!data) {
            return NOT_LOADED;
        }

        if (!isLoaded(data.projects)) {
            return NOT_LOADED;
        }

        return arrayify(data.projects);
    }
);

export const getCurrentProject = createSelector(
    [ getFirestoreOrderedData ],
    (data) => {
        if (!data) {
            return NOT_LOADED;
        }

        if (!isLoaded(data.currentProject)) {
            return NOT_LOADED;
        }

        return data.currentProject && data.currentProject.length ?
            data.currentProject[0] : null;
    }
);

export const getGoals = createSelector(
    [ getOptimisticData ],
    (data) => {
        if (!data) {
            return NOT_LOADED;
        }

        if (!isLoaded(data.goals)) {
            return NOT_LOADED;
        }

        return arrayify(data.goals, true);
    }
);

export const getFilteredGoals = createSelector(
    [getGoals, getStoryMap],
    (goals, { showClosed }) => {
        if (!isLoaded(goals)) {
            return NOT_LOADED;
        }

        return showClosed ? goals : (goals || []).filter(c => c.status !== 1);
    }
);

export const getStories = createSelector(
    [ getOptimisticData ],
    (data) => {
        if (!data) {
            return NOT_LOADED;
        }

        if (!isLoaded(data.stories)) {
            return NOT_LOADED;
        }

        return arrayify(data.stories, true);
    }
);

export const getFilteredStories = createSelector(
    [getStories, getStoryMap],
    (stories, { showClosed }) => {
        if (!isLoaded(stories)) {
            return NOT_LOADED;
        }

        return showClosed ? stories : (stories || []).filter(c => c.status !== 1);
    }
);

export const getReleases = createSelector(
    [ getOptimisticData ],
    (data) => {
        if (!data) {
            return NOT_LOADED;
        }

        if (!isLoaded(data.releases)) {
            return NOT_LOADED;
        }

        return arrayify(data.releases, true);
    }
);

export const getFilteredReleases = createSelector(
    [getReleases, getStoryMap],
    (releases, { showClosed }) => {
        if (!isLoaded(releases)) {
            return NOT_LOADED;
        }

        return showClosed ? releases : (releases || []).filter(c => c.status !== 1);
    }
);

export const getTasks = createSelector(
    [ getOptimisticData ],
    (data) => {
        if (!data) {
            return NOT_LOADED;
        }

        if (!isLoaded(data.tasks)) {
            return NOT_LOADED;
        }

        return arrayify(data.tasks, true);
    }
);

export const getFilteredTasks = createSelector(
    [getTasks, getStoryMap],
    (tasks, { showClosed }) => {
        if (!isLoaded(tasks)) {
            return NOT_LOADED;
        }

        return showClosed ? tasks : (tasks || []).filter(c => c.status !== 1);
    }
);

export const getCollections = createSelector(
    [getGoals, getStories, getReleases, getTasks],
    (goals, stories, releases, tasks) => {
        if (!isLoaded(goals, stories, releases, tasks)) {
            return;
        }

        return {
            goals,
            stories,
            releases,
            tasks
        };
    }
);

const getTeams = createSelector(
    [ getFirestoreData ],
    (data) => {
        if (!data) {
            return NOT_LOADED;
        }

        if (!isLoaded(data.teams)) {
            return NOT_LOADED;
        }

        return arrayify(data.teams, false);
    }
);

export const getTeam = createSelector(
    [ getTeams ],
    (teams) => {
        if (!isLoaded(teams)) {
            return NOT_LOADED;
        }

        return teams && teams.length ? teams[0] : null;
    }
);

export const getProfiles = createSelector(
    [ getFirestoreData ],
    (data) => {
        if (!data) {
            return NOT_LOADED;
        }

        if (!isLoaded(data.profiles)) {
            return NOT_LOADED;
        }

        return arrayify(data.profiles, false);
    }
);

export const getRequests = createSelector(
    [ getFirestoreData ],
    (data) => {
        if (!data) {
            return NOT_LOADED;
        }

        if (!isLoaded(data.requests)) {
            return NOT_LOADED;
        }

        return arrayify(data.requests, false);
    }
);

const getAuth = (state) => {
    if (!state || !state.auth) {
        return {};
    }

    return state.auth;
};

export const getAuthExists = createSelector(
    [ getAuth ],
    (auth) => {
        return !!auth && !!auth.user && !!auth.user.uid;
    }
);

export const getIsStoryMapEditEnabled = (({ storymap: { isEditModeEnabled }}) => isEditModeEnabled);

export const getEditCardState = createSelector(
    [
        getAuthExists,
        getCurrentProject,
        getStoryMap, 
        getGoals,
        getStories,
        getReleases,
        getTasks
    ],
    (authExists, project, storyMap, goals, stories, releases, tasks) => {
        let result = {
            ...storyMap
        };

        result.isEditModeEnabled = result.isEditModeEnabled && (authExists || project.isPublic);

        switch (result.editCardType) {
            case CardTypes.GOAL:
                result.collection = goals;
                result.collectionName = 'goals';
                break;
            case CardTypes.STORY:
                result.collection = stories;
                result.collectionName = 'stories';
                break;
            case CardTypes.RELEASE:
                result.collection = releases;
                result.collectionName = 'releases';
                break;
            case CardTypes.TASK:
                result.collection = tasks;
                result.collectionName = 'tasks';
                break;
            default:
                break;
        }
    
        return result;
    }
);