import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import { requireAuth, ApprovalRequired, Login, Register, Reset } from '../auth';

// const Home = lazy(() => 
//     import(/* webpackPrefetch: true, webpackChunkName: "home" */'../public'));
// const ProfileLayout = lazy(() => 
//     import(/* webpackPrefetch: true, webpackChunkName: "profile" */'../profile'));
// const ProjectsLayout = lazy(() => 
//     import(/* webpackPrefetch: true, webpackChunkName: "projects" */'../projects'));
// const StoryMapLayout = lazy(() => 
//     import(/* webpackPrefetch: true, webpackChunkName: "storymap" */'../storymap'));
// const TeamLayout = lazy(() => 
//     import(/* webpackPrefetch: true, webpackChunkName: "team" */'../team'));

import { default as Home } from '../public';
import { default as ProfileLayout } from '../profile';
import { default as ProjectsLayout } from '../projects';
import { default as StoryMapLayout } from '../storymap';
import { default as TeamLayout } from '../team';

export default (
    <Switch>
        <Route exact path="/login" component={ Login } />
        <Route exact path="/register" component={ Register } />
        <Route exact path="/reset" component={ Reset } />
        <Route exact path="/profile" component={ ProfileLayout } />
        <Route exact path="/approval-required" component={ requireAuth(ApprovalRequired) } />
        <Route exact path="/projects" component={ requireAuth(ProjectsLayout) } />
        <Route path="/project/:teamId/:id" component={ StoryMapLayout } />
        <Route exact path="/team" component={ requireAuth(TeamLayout) } />
        <Route exact path="/" component={ Home } />
    </Switch>
);