import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { getTeam, getProfiles, getRequests } from 'data/selectors';
import { withAuth } from 'modules/auth';

import { default as Component } from '../components/TeamLayout';

export default compose(
    withAuth,
    firestoreConnect(({ auth: { user } }) => {
        return [
            {
                collection: 'teams',
                doc: user.teamId
            },
            {
                collection: 'profiles',
                where: [
                    ['teamId', '==', user.teamId ]
                ]
            },
            {
                collection: 'requests',
                where: [
                    ['type', '==', 'team'],
                    ['teamId', '==', user.teamId ]
                ]
            }
        ];
    }),
    connect((state) => {
        return {
            team: getTeam(state),
            profiles: getProfiles(state),
            requests: getRequests(state)
        };
    })
)(Component);