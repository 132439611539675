import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { toggleEditProjectDialog } from '../actions';
import FormActions from 'common/components/FormActions';
import useKeyPress from 'common/hooks/useKeyPress';

export default function CreateProject() {
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const history = useHistory();
    const nameRef = useRef(null);

    const moduleState = useSelector(({ projects }) => projects);
    const user = useSelector(({ auth: { user }}) => user);

    const [isBusy, setIsBusy] = useState(false);
    useKeyPress('Escape', {
        onKeyUp: () => {
            // Only run for close
            if (moduleState.isEditProjectDialogOpen) {
                dispatch(toggleEditProjectDialog());
            }
        }
    })

    useEffect(() => {
        nameRef.current.focus();
    }, [moduleState.isEditProjectDialogOpen])

    async function save() {
        const project = {
            name: nameRef.current.value,
            teamId: user.teamId,
            isPublic: false
        };

        await setIsBusy(true);

        let result = null;

        if (moduleState.project && moduleState.project.id) {
            result = await firestore.collection('projects').doc(moduleState.project.id).set(project);
        }
        else {
            result = await firestore.collection('projects').add(project);

            await firestore.collection('releases').add({
                name: 'Unscheduled',
                status: 0,
                order: 0,
                teamId: user.teamId,
                projectId: result.id
            });
        }

        await dispatch(toggleEditProjectDialog());
        await setIsBusy(false);

        history.push(`/project/${user.teamId}/${result.id}`);
    }

    function cancel() {
        nameRef.current.value = '';

        dispatch(toggleEditProjectDialog());
    }

    function onKeyPress(e) {
        if (e.key === 'Enter') {
            save();
        }
    }

    const classes = classNames('modal', {
        'modal-open': moduleState.isEditProjectDialogOpen
    });

    return (
        <div className={classes}>
            <div className='form-group form-row'>
                <label htmlFor='projectNameInput'>Project Name</label>
                <input type='text' 
                    className='form-control'
                    id='projectNameInput'
                    placeholder='Enter a name for your project'
                    ref={nameRef}
                    defaultValue={moduleState.project ? moduleState.project.name : ''}
                    onKeyPress={onKeyPress} />
            </div>

            <FormActions isBusy={isBusy}
                actionText={moduleState?.project?.id ? 'Save' : 'Add'}
                action={save}
                cancel={cancel} />
        </div>
    );
}