import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import CenterCard from 'common/components/CenterCard';
import Page from 'common/components/Page';
import { getAuthExists } from 'data/selectors';

export default function Reset() {
    const firebase = useFirebase();
    const { handleSubmit, register, formState } = useForm();

    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState(null);
    const [isInitiated, setIsInitiated] = useState(false);
    const authExists = useSelector(getAuthExists);

    if (authExists) {
        let location = window.location.search.split('=')[1] || '/projects';
        return <Redirect to={location} />;
    }

    async function reset(values) {
        if (!values.email) {
            return;
        }

        await setIsBusy(true);
        await setError(null);

        try {
            await firebase.auth().sendPasswordResetEmail(values.email);

            await setIsInitiated(true);
        }
        catch (e) {
            await setError(e?.message || 'Unknown failure');
        }
        finally {
            setIsBusy(false);
        }
    }

    if (isInitiated) {
        return (
            <Page title='Reset Password'>
                <CenterCard>
                    <div className='card'>
                        <div className='card-header bg-primary'>
                            <h2>Password Reset Initiated</h2>
                        </div>

                        <div className='card-body mt-4'>
                            <p>Your request to reset your password has been submitted.</p>
                            <p>Please check your email, reset your password, and try logging in again.</p>
                        </div>
                    </div>
                </CenterCard>
            </Page>
        );
    }

    const formClassName = classNames('needs-validation', {
        'was-validated': formState.isSubmitted
    });
    const labelClassName = 'col-5 col-sm-4 col-md-3 col-lg-2 col-form-label';
    const inputClassName = 'form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10';

    return (
        <Page title='Reset Password' className='row justify-content-sm-center'>
            <div className='col-12 col-md-8 col-lg-5'>
                <div className='card'>
                    <div className='card-header'>
                        <h2>Reset Password</h2>
                    </div>

                    <div className='card-body mt-4'>
                        <form onSubmit={handleSubmit(reset)} className={formClassName} noValidate>
                            <div className='form-group form-row'>
                                <label htmlFor='email' className={labelClassName}>Email</label>
                                <input type='email' className={inputClassName} id='email' name='email' required disabled={isBusy} ref={register({ required: 'Required' })} />
                            </div>

                            {
                                error ? (
                                    <div className='form-group row'>
                                        <span className='col-12 text-danger'>{error}</span>
                                    </div>
                                ) : null
                            }

                            <div className='position-relative mt-4'>
                                <button className='btn btn-primary' type='submit' disabled={isBusy || formState.isSubmitting}>
                                    {isBusy || formState.isSubmitting ?
                                        <span className='spinner-border spinner-border-sm mr-1'></span> : null}
                                    {isBusy || formState.isSubmitting ?
                                        'Working on it...' : 'Send Password Reset Link'}
                                </button>
                                &nbsp;or&nbsp;<Link to='/login'>login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Page>
    );
}