import { useState, useEffect } from 'react';

export default function useKeyPress(targetKey, options) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
        if (key === targetKey) {
            setKeyPressed(false);
            options?.onKeyUp && options.onKeyUp();
        }
    };
    
    // Add event listeners
    useEffect(() => {
        window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keyup', upHandler);
        };
    }, [options]); // Empty array ensures that effect is only run on mount and unmount

    return keyPressed;
}