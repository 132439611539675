import * as t from './actionTypes';

const initialState = {
    isBusy: false
};

export default (state = initialState, action) => {
    switch(action.type) {
        case t.TOGGLE_BUSY:
            const isBusy = !state.isBusy;
            return {
                ...state,
                isBusy
            };
        default:
            return state;
    }
};