import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import classNames from 'classnames';

import { setUser } from 'modules/auth/actions';

export default function EditProfile(props) {
    const dispatch = useDispatch();
    const firebase = useFirebase();
    const firestore = useFirestore();

    const profile = useSelector(({ auth: { user } }) => ({
        name: user?.name || '',
        email: user?.email || '',
        teamName: user?.team?.teamName || '',
        uid: user?.uid
    }));

    const profileForm = useForm();
    const [profileError, setProfileError] = useState(null);

    const passwordForm = useForm();
    const [passwordError, setPasswordError] = useState(null);

    async function saveProfile(values) {
        try {
            if (values.name === profile.name) {
                throw new Error('name has not been udpated!');
            }

            const profileRef = firestore.collection('profiles').doc(profile.uid);
            const fullProfile = await profileRef.get();

            await profileRef.set({
                ...fullProfile.data(),
                name: values.name
            });

            await dispatch(setUser({
                name: values.name
            }));
        }
        catch (e) {
            setProfileError(e?.message || 'unknown error');
        }
    }

    async function updatePassword(values) {
        try {
            let user = firebase.auth().currentUser;

            if (!values.password || values.password === '') {
                return;
            }

            let authFailed = false;

            try {
                await firebase.auth().signInWithEmailAndPassword(profile.email, values.oldPassword);
            }
            catch (authError) {
                setPasswordError('Error encountered while validating current password: ' + authError?.message || '')
                authFailed = true;
            }

            if (authFailed) {
                return;
            }

            await user.updatePassword(values.password);
        }
        catch (e) {
            setPasswordError(e?.message || 'unknown error');
        }
    }

    async function openDialog(e) {
        e.preventDefault();

        props.setIsModalOpen(true);
    }

    const profileFormClassName = classNames('needs-validation', {
        'was-validated': profileForm.formState.isSubmitted
    });
    const passwordFormClassName = classNames('needs-validation', {
        'was-validated': passwordForm.formState.isSubmitted
    });
    const labelClassName = 'col-5 col-sm-4 col-md-3 col-lg-2 col-form-label';
    const inputClassName = 'form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10';
    const invalidClassName = 'col-7 col-sm-8 col-md-9 col-lg-10 offset-5 offset-sm-4 offset-md-3 offset-lg-2 invalid-feedback';

    return (
        <div>
            <form onSubmit={profileForm.handleSubmit(saveProfile)} className={profileFormClassName} noValidate>
                <h4 className='mb-4'>Update Profile</h4>

                <div className='form-group form-row'>
                    <label htmlFor='name' className={labelClassName}>Name</label>
                    <input type='text' className={inputClassName} id='name' name='name' required disabled={profileForm.formState.isSubmitting} placeholder={profile.name} ref={profileForm.register({ required: 'Required' })} />
                    <div className={invalidClassName}>{profileForm.errors?.name?.message}</div>
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='email' className={labelClassName}>Email</label>
                    <input type='email' className={inputClassName} id='email' name='email' required disabled={true} value={profile.email} ref={profileForm.register()} />
                    <div className={invalidClassName}>{profileForm.errors?.email?.message}</div>
                </div>
                <div className='form-group form-row'>
                    <label htmlFor='teamName' className={labelClassName}>Team</label>
                    <input type='text' className={inputClassName} id='teamName' name='teamName' required disabled={true} value={profile.teamName} ref={profileForm.register()} />
                    <div className={invalidClassName}>{profileForm.errors?.teamName?.message}</div>
                </div>

                {
                    profileError ? (
                        <div className='form-group row'>
                            <span className='col-12 text-danger'>{profileError}</span>
                        </div>
                    ) : null
                }

                <button className='btn btn-primary' type='submit' disabled={profileForm.formState.isSubmitting}>
                    {profileForm.formState.isSubmitting ?
                        <span className='spinner-border spinner-border-sm mr-1'></span> : null}
                    {profileForm.formState.isSubmitting ?
                        'Working on it...' : 'Update Profile'}
                </button>
            </form>

            <hr />

            <form onSubmit={passwordForm.handleSubmit(updatePassword)} className={passwordFormClassName} noValidate>
                <h4 className='mb-4'>Change Password</h4>

                <div className='form-group form-row'>
                    <label htmlFor='oldPassword' className={labelClassName}>Current Password</label>
                    <input type='password' className={inputClassName} id='oldPassword' name='oldPassword' required ref={passwordForm.register({ required: 'Required' })} />
                    <div className={invalidClassName}>{passwordForm.errors?.oldPassword?.message}</div>
                </div>

                <div className='form-group form-row'>
                    <label htmlFor='password' className={labelClassName}>New Password</label>
                    <input type='password' className={inputClassName} id='password' name='password' required ref={passwordForm.register({ required: 'Required' })} />
                    <div className={invalidClassName}>{passwordForm.errors?.password?.message}</div>
                </div>

                {
                    passwordError ? (
                        <div className='form-group row'>
                            <span className='col-12 text-danger'>{passwordError}</span>
                        </div>
                    ) : null
                }

                <button className='btn btn-primary' type='submit' disabled={passwordForm.formState.isSubmitting}>
                    {passwordForm.formState.isSubmitting ?
                        <span className='spinner-border spinner-border-sm mr-1'></span> : null}
                    {passwordForm.formState.isSubmitting ?
                        'Working on it...' : 'Change Password'}
                </button>
            </form>

            <hr />

            <div>
                <h4 className='mb-4'>Delete Account</h4>

                <button type='button' className='btn btn-danger' onClick={openDialog}>Delete Account</button>
            </div>
        </div>
    );
}