import * as t from './actionTypes';

export function toggleBusy(day) {
    return {
        type: t.TOGGLE_BUSY
    };
}

export function preemptFirestoreUpdate(collectionKey, update) {
    return {
        type: t.FIRESTORE_OPTIMISTIC_UPDATE,
        payload: {
            key: collectionKey,
            update
        }
    };
}

export function revertPreemptiveFirestoreUpdate(collectionKey) {
    return {
        type: t.FIRESTORE_OPTIMISTIC_REVERT,
        payload: {
            key: collectionKey
        }
    };
}