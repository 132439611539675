import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Loading from 'common/components/Loading';

export default function Home() {
    const authStatus = useSelector(({ auth }) => ({
        isAuthenticated: !!auth && !!auth.user && !!auth.user.uid,
        isLoaded: !!auth && auth.isLoaded
    }));

    if (authStatus.isAuthenticated) {
        return <Redirect to='/projects' />;
    }
    else if (!authStatus.isLoaded) {
        return <Loading />;
    }

    return (
        <main className='overflow-hidden'>
            <Helmet>
                <title>Fable - Story Mapping</title>
            </Helmet>

            <article className='d-flex vw-100 vh-100 justify-content-center align-items-center'>
                <div className='card'>
                    <div className='card-body mt-2 text-center'>
                        <p className='lead'>
                            Visualize, prioritize, and efficiently map out requirements for your next project.
                        </p>
                        <p>
                            <Link className='btn btn-outline-primary btn-lg' to='/register' role='button'>Register</Link>
                                &nbsp;&nbsp;
                            <Link className='btn btn-outline-dark btn-lg' to='/login' role='button'>Login</Link>
                        </p>
                    </div>
                </div>
            </article>
        </main>
    );
}