import * as t from './actionTypes';

const initialState = {
    isEditProjectDialogOpen: false,
    editProject: null
};

export default (state = initialState, action) => {
    switch(action.type) {
        case t.TOGGLE_CREATE_PROJECT_DIALOG:
            const isEditProjectDialogOpen = !state.isEditProjectDialogOpen;
            return {
                ...state,
                isEditProjectDialogOpen,
                editProject: isEditProjectDialogOpen ? action.project : null
            };
        default:
            return state;
    }
};