import React from 'react';
import { useDispatch } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import { RELEASE } from '../constants';
import { toggleEditDialog } from '../actions';

export default function ReleaseContainer(props) {
    const dispatch = useDispatch();

    function edit(e) {
        e.preventDefault();

        dispatch(toggleEditDialog(RELEASE));
    }

    return (
        <Droppable droppableId='releases' type={ RELEASE }>
            {(provided, snapshot) => (
                <div className={classNames({
                        'storymap-release-target': snapshot.isDraggingOver
                    })}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {props.children}
                    {provided.placeholder}

                    { props.isEditModeEnabled ? (
                        <fieldset className='storymap-release'>
                            <legend className='clickable'>
                                <a href='#' className='storymap-release-create delinkify' onClick={edit}>+ New Release</a>
                            </legend>
                        </fieldset>
                    ) : null }
                </div>
            )}
        </Droppable>
    );
};