import React from 'react';
import { useDispatch } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import { STORY } from '../constants';
import { toggleEditDialog } from '../actions';

export default function StoryContainer(props) {
    const dispatch = useDispatch();

    const addNewClassName = classNames('storymap-card storymap-card-create delinkify', {
        'storymap-card-sm-for-row': (props.children?.length > 0)
    });

    function edit(e) {
        e.preventDefault();

        dispatch(toggleEditDialog(STORY, { goalId: props.goal.id }))
    }

    return (
        <Droppable droppableId={props.goal.id} type={ STORY } direction='horizontal'>
            {(provided, snapshot) => (
                <div className='d-flex flex-row'
                    ref={provided.innerRef}
                    style={{ border: snapshot.isDraggingOver ? '1px dashed #ccc' : 'initial' }}
                    {...provided.droppableProps}
                >
                    {props.children}
                    {provided.placeholder}
                    
                    { props.isEditModeEnabled ? 
                        <a href='#' className={addNewClassName} onClick={edit}>+</a> : null }
                </div>
            )}
        </Droppable>
    );
};