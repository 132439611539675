import React from 'react';
import { useDispatch } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';

import { GOAL } from '../constants';
import { toggleEditDialog } from '../actions';

export default function GoalContainer(props) {
    const dispatch = useDispatch();

    function create(e) {
        e.preventDefault();

        dispatch(toggleEditDialog(GOAL));
    }

    return (
        <Droppable droppableId='goals' type={ GOAL } direction='horizontal'>
            {(provided, snapshot) => (
                <div className='d-flex flex-row'
                    ref={provided.innerRef}
                    style={{ border: snapshot.isDraggingOver ? '1px dashed #ccc' : 'initial' }}
                    {...provided.droppableProps}
                >
                    <div className='storymap-actions-container'>{/*spacer*/}</div>

                    {props.children}
                    {provided.placeholder}

                    { props.isEditModeEnabled ? (
                        <div>
                            <a href='#' className='storymap-card storymap-card-create delinkify'
                                onClick={create}>+</a>
                            <div className='d-flex flex-row'>
                                <div className='storymap-card'></div> {/*frozen spacer*/}
                            </div>
                        </div>
                    ) : null }
                </div>
            )}
        </Droppable>
    );
};