import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

import Loading from 'common/components/Loading';
import Link from './Link';
import UserMenu from './UserMenu';
import routes from '../routes';

export default function MainLayout() {
    const { app, auth} = useSelector(({ app, auth }) => ({ app, auth }));

    const navClassName = classNames('navbar navbar-expand-lg navbar-light bg-light fixed-top', {
        'is-busy': app.isBusy
    });

    return (
        <div>
            <nav className={navClassName}>
                <RouterLink className='navbar-brand mb-0 h1' to={ auth?.user?.uid ? '/projects' : '/' }>Fable</RouterLink>
                <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#mainNav' aria-controls='mainNav' aria-expanded='false' aria-label='Toggle navigation'>
                    <span className='navbar-toggler-icon'></span>
                </button>

                <div className='collapse navbar-collapse' id='mainNav'>
                    { auth?.user?.uid && auth?.user?.teamId ? (
                        <ul className='navbar-nav mr-auto'>
                            <Link to='/projects' target='#mainNav'>Projects</Link>
                            <Link to='/team' target='#mainNav'>Team</Link>
                        </ul>
                    ) : <ul className='navbar-nav mr-auto'></ul> }

                    <UserMenu />
                </div>
            </nav>
            
            <Suspense fallback={<Loading />}>
                {routes}
            </Suspense>
        </div>
    );
}