import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import * as Fathom from 'fathom-client';

var lastLocation = null;

export default function TrackedRouter({ component }) {
    if (process.env.NODE_ENV === 'development') {
        return (
            <Route path="/" component={ component } />
        );
    }

    const location = useLocation();

    useEffect(() => {
        Fathom.load('AWZLUXHZ', {
            excludedDomains: ['localhost']
        });
        Fathom.trackPageview();
    }, []);

    if (lastLocation !== location.pathname) {
        Fathom.trackPageview();
        lastLocation = location.pathname;
    }

    return (
        <Route path="/" component={ component } />
    );
}