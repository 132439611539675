import { setUser, setAuthLoaded } from './actions';
import { getFirebase } from 'react-redux-firebase';

import store from 'modules/app/store';
import firebase from 'data/firebase';

export default function initAuth() {
    // TODO: remove after react-redux-firebase auth is fixed
    firebase.auth().onAuthStateChanged(async authData => {
        if (!authData) {
            store.dispatch(setUser(null));
        }
        else {
            let firestore = getFirebase().firestore();

            let profileContainer = await firestore.collection('profiles')
                .doc(authData.uid)
                .get();

            let profile = profileContainer ? profileContainer.data() : null;
            let team = null;
            let request = null;
            
            if (profile && profile.teamId) {
                let teamContainer = await firestore.collection('teams')
                    .doc(profile.teamId)
                    .get();

                if (teamContainer) {
                    team = teamContainer.data();
                }
            }
            else {
                let joinRequest = await firestore.collection('requests')
                    .where('uid', '==', authData.uid)
                    .limit(1)
                    .get();

                if (joinRequest && !joinRequest.empty) {
                    request = joinRequest.docs[0].data();
                }
            }

            store.dispatch(setUser({
                ...profile,
                team: { ...team },
                request: { ...request },
                uid: authData.uid,
                email: authData.email
            }));
        }

        store.dispatch(setAuthLoaded(true));
    });
}