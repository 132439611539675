import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { toggleGoal } from '../actions';
import { GOAL } from '../constants';
import Card from './Card';
import StoryContainer from './StoryContainer';

export default function Goal(props) {
    const dispatch = useDispatch();

    const children = React.Children.toArray(props.children);

    const collapsedGoals = useSelector(({ storymap: { collapsedGoals }}) => collapsedGoals);
    const isCollapsed = collapsedGoals.includes(props.goal.id);

    const collapseClassName = classNames('storymap-collapse', {
        'storymap-collapsed': isCollapsed
    });

    function toggleCollapsed(e) {
        e.preventDefault();

        dispatch(toggleGoal(props.goal.id));
    }

    return (
        <Draggable draggableId={props.goal.id} index={props.index} isDragDisabled={!props.isEditModeEnabled}>
            {provided => (
                <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className='storymap-goal'
                >
                    <div className='d-flex'>
                        <Card type={GOAL} card={props.goal} isEditModeEnabled={props.isEditModeEnabled} />

                        { children.length > 1 || isCollapsed ? (
                            <a href='#' className='storymap-goal-collapse delinkify' onClick={toggleCollapsed} title='Collapse goal'>
                                <FontAwesomeIcon icon={['fas', 'caret-right']} className={collapseClassName} />
                            
                                { !isCollapsed ? <span className='storymap-goal-collapse-hint'>Collapse Goal</span> : null }
                            </a>
                        ) : null }
                    </div>

                    <StoryContainer goal={props.goal} isEditModeEnabled={props.isEditModeEnabled} isCollapsed={isCollapsed}>
                        {props.children}
                    </StoryContainer>
                </div>
            )}
        </Draggable>
    );
};