import React from 'react';
import { Helmet } from 'react-helmet';

import Loading from './Loading';

export default ({ className = 'mb-4', children, isLoading, title }) => {
    return (
        <main className='app-body'>
            <Helmet>
                <title>Fable - {title}</title>
            </Helmet>
            
            <div className={className}>
                {isLoading ? <Loading /> : children}
            </div>
        </main>
    );
};