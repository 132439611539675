import { library } from '@fortawesome/fontawesome-svg-core';

import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons/faCommentAlt';

library.add(
    faCaretDown,
    faCaretRight,
    faCheck,
    faCog,
    faCommentAlt,
    faEllipsisV,
    faEye,
    faLock,
    faPencilAlt,
    faPlus,
    faSave,
    faSearch,
    faThumbsDown,
    faThumbsUp,
    faTimes,
    faTrashAlt,
    faUser
);