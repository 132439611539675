import React, { Component } from 'react';
import { defaultMemoize } from 'reselect';
import { isLoaded } from 'react-redux-firebase';

import CenterCard from 'common/components/CenterCard';
import Page from 'common/components/Page';
import DeleteProject from './DeleteProject';
import EditCard from '../components/EditCard';
import StoryMapContainer from './StoryMapContainer';
import GoalContainer from './GoalContainer';
import Goal from './Goal';
import Story from './Story';
import ReleaseContainer from './ReleaseContainer';
import Release from './Release';
import ReleaseFlyout from './ReleaseFlyout';
import StoryMapActions from './StoryMapActions';
import TaskContainer from './TaskContainer';
import Task from './Task';
import * as CardTypes from '../constants';
import EditProject from './EditProject';

class StoryMapLayout extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: false
        };
    }

    toggleLoading = async (isLoading) => {
        //await this.setState({ isLoading });
        await this.props.toggleBusy();
    }

    editProject = (e) => {
        e.preventDefault();

        this.props.toggleEditProjectDialog();
    }

    deleteProject = (e) => {
        e.preventDefault();

        this.props.toggleDeleteProjectDialog();
    }

    move = async (target, destination, parentIdProps) => {
        // Sort any collections we're given
        let targetCollection = target.collection ? target.collection.sort((a, b) => a.order - b.order) : null;
        let destinationCollection = destination.collection.sort((a, b) => a.order - b.order);
        let updates = [];

        const differentParent = parentIdProps && parentIdProps.length ? parentIdProps.reduce((prev, curr, i) => {
            return target.doc[curr] !== destination.parentIds[i] || !!prev;
        }, false) : false;

        // If there isn't a parent, then we only need to update one collection
        if (differentParent) {
            targetCollection.splice(target.index, 1);

            targetCollection.slice(target.index).forEach((d, index) => {
                updates.push({
                    ...d,
                    order: index
                });
            });

            parentIdProps.forEach((prop, index) => {
                target.doc[prop] = destination.parentIds[index];
            });
        }
        else {
            // Remove from old index before reinserting
            destinationCollection.splice(target.index, 1);
        }

        destinationCollection.splice(destination.index, 0, target.doc);

        destinationCollection.forEach((d, index) => {
            updates.push({
                ...d,
                order: index
            });
        });

        let optimisticCollection = [...this.props[target.collectionName]];
        updates.forEach(update => {
            optimisticCollection.splice(
                optimisticCollection.findIndex(r => r.id === update.id),
                1,
                update
            );
        });
        await this.props.preemptFirestoreUpdate(target.collectionName, optimisticCollection);

        let batch = this.props.firestore.batch();

        for (let i = 0, len = updates.length; i < len; i++) {
            const update = updates[i];
            const ref = this.props.firestore.collection(target.collectionName).doc(update.id);
            await batch.set(ref, update);
        }

        await batch.commit();
    }

    moveTask = async (taskId, droppableId, sourceIndex, destinationIndex) => {
        let task = { ...this.props.tasks.find(t => t.id === taskId) };
        let [releaseId, storyId] = droppableId.split('-');

        await this.move({
            doc: task,
            collection: this.props.tasks.filter(t => t.storyId === task.storyId && t.releaseId === task.releaseId),
            collectionName: 'tasks',
            index: sourceIndex
        }, {
            collection: this.props.tasks.filter(t => t.storyId === storyId && t.releaseId === releaseId),
            parentIds: [releaseId, storyId],
            index: destinationIndex
        }, ['releaseId', 'storyId']);
    }

    moveStory = async (storyId, goalId, sourceIndex, destinationIndex) => {
        let story = { ...this.props.stories.find(s => s.id === storyId) };

        await this.move({
            doc: story,
            collection: this.props.stories.filter(s => s.goalId === story.goalId),
            collectionName: 'stories',
            index: sourceIndex
        }, {
            collection: this.props.stories.filter(s => s.goalId === goalId),
            parentIds: [goalId],
            index: destinationIndex
        }, ['goalId']);
    }

    moveRelease = async (releaseId, sourceIndex, destinationIndex) => {
        let release = { ...this.props.releases.find(r => r.id === releaseId) };

        await this.move({
            doc: release,
            collectionName: 'releases',
            index: sourceIndex
        }, {
            collection: this.props.releases,
            index: destinationIndex
        });
    }

    moveGoal = async (goalId, sourceIndex, destinationIndex) => {
        let goal = { ...this.props.goals.find(g => g.id === goalId) };

        await this.move({
            doc: goal,
            collectionName: 'goals',
            index: sourceIndex
        }, {
            collection: this.props.goals,
            index: destinationIndex
        });
    }

    onDragEnd = async (result) => {
        const { draggableId, type, source, destination } = result;

        if (!destination || destination.index == null) {
            return;
        }

        await this.toggleLoading(true)

        switch (type) {
            case CardTypes.GOAL:
                await this.moveGoal(draggableId, source.index, destination.index);
                break;
            case CardTypes.STORY:
                await this.moveStory(draggableId, destination.droppableId, source.index, destination.index);
                break;
            case CardTypes.RELEASE:
                await this.moveRelease(draggableId, source.index, destination.index);
                break;
            case CardTypes.TASK:
                await this.moveTask(draggableId, destination.droppableId, source.index, destination.index);
                break;
        }

        await this.toggleLoading(false);
    }

    getTasksByStoryAndRelease = defaultMemoize((tasks, storyId, releaseId) => {
        return tasks.filter(t => t.releaseId === releaseId && t.storyId === storyId)
    })

    renderTaskContainer = (release, story, index) => {
        if (story === CardTypes.GOAL_NO_STORY_PLACEHOLDER) {
            return <div className='storymap-card placeholder-row' key={index}></div>;
        }
        else if (story === CardTypes.STORY_CREATE_PLACEHOLDER) {
            return this.props.isEditModeEnabled ?
                <div className='storymap-card storymap-card-sm-for-row' key={index}></div> : null;
        }
        else if (story === CardTypes.GOAL_COLLAPSE_PLACEHOLDER) {
            return this.props.isEditModeEnabled ? 
                <div className='storymap-card storymap-card-sm-for-row' key={index}></div> :
                <div className='placeholder-row'></div>;
        }

        const tasks = this.props.tasks;

        return (
            <TaskContainer releaseId={release.id} storyId={story.id} key={`${release.id} -${story.id}`} 
                isEditModeEnabled={this.props.isEditModeEnabled}>
                {
                    this.getTasksByStoryAndRelease(tasks || [], story.id, release.id).map((t, i) => 
                        <Task task={t} key={t.id} index={i} isEditModeEnabled={this.props.isEditModeEnabled} />)
                }
            </TaskContainer>
        );
    }

    getStoriesByGoals = defaultMemoize((goals, stories, collapsedGoals) => {
        return goals.map(g => {
            const filteredStories = stories.filter(s => s.goalId === g.id);
            const isCollapsed = collapsedGoals.includes(g.id);

            return {
                goalId: g.id,
                isCollapsed,
                stories: isCollapsed && filteredStories.length > 0 ? 
                    [filteredStories[0]] : filteredStories
            };
        }).reduce((prev, curr) => {
            if (!curr || !curr.stories || !curr.stories.length) {
                return prev.concat(CardTypes.GOAL_NO_STORY_PLACEHOLDER);
            }

            return prev.concat(curr.stories).concat(
                curr.isCollapsed ? CardTypes.GOAL_COLLAPSE_PLACEHOLDER : CardTypes.STORY_CREATE_PLACEHOLDER);
        }, []);
    })

    renderRelease = (release, index) => {
        const {
            goals,
            stories,
            collapsedGoals
        } = this.props;

        return (
            <Release key={release.id} release={release} index={index} isEditModeEnabled={this.props.isEditModeEnabled}>
                {
                    this.getStoriesByGoals(goals || [], stories || [], collapsedGoals).map((story, i) => 
                        this.renderTaskContainer(release, story, i))
                }
            </Release>
        );
    }

    getStoriesByGoalId = defaultMemoize((stories, goalId, collapsedGoals) => {
        const filteredStories = stories.filter(story => story.goalId === goalId);
        return collapsedGoals.includes(goalId) && filteredStories.length > 0 ? 
            [filteredStories[0]] : filteredStories;
    })

    renderGoal = (goal, index) => {
        const {
            collapsedGoals,
            stories
        } = this.props;

        return (
            <Goal key={goal.id} goal={goal} index={index} isEditModeEnabled={this.props.isEditModeEnabled}>
                {
                    this.getStoriesByGoalId(stories || [], goal.id, collapsedGoals).map((story, i) => (
                        <Story key={story.id} story={story} index={i} isEditModeEnabled={this.props.isEditModeEnabled} />
                    ))
                }
            </Goal>
        );
    }

    render() {
        const {
            project,
            goals,
            stories,
            releases,
            tasks,
            isAuthenticated,
            isEditModeEnabled
        } = this.props;

        if (this.props.errors?.allIds?.length) {
            return (
                <Page title='Error'>
                    <CenterCard>
                        <div className='card'>
                            <div className='card-header bg-warning'>
                                <h2>Error</h2>
                            </div>

                            <div className='card-body mt-4'>
                                <p>There was an error encountered while loading the requested resource.</p>
                                <p>Please confirm you are logged in and accessing the correct project then try again.</p>
                            </div>
                        </div>
                    </CenterCard>
                </Page>
            );
        }

        if (!isLoaded(project, goals, stories, releases, tasks) ||
            this.props.match.params.id !== project?.id ||
            this.state.isLoading) {
                return <Page title='Project' isLoading={true} />
        }

        return (
            <Page title='Project' className='storymap-container'>
                <header className='storymap-header'>
                    <div className='dropdown'>
                        <h3 className='dropdown-toggle clickable' data-toggle='dropdown'>
                            {project.name}
                        </h3>
                        
                        { isEditModeEnabled ? (
                            <div className='dropdown-menu'>
                                <a href='#' className='dropdown-item' onClick={this.editProject}>Edit</a>
                                <div className='dropdown-divider'></div>
                                <a href='#' className='dropdown-item' onClick={this.deleteProject}>Delete</a>
                            </div>
                        ) : (
                            <div className='dropdown-menu'>
                                <a href='#' className='dropdown-item' onClick={this.editProject}>View Details</a>
                            </div>
                        ) }

                        <StoryMapActions project={project} isAuthenticated={isAuthenticated} isEditModeEnabled={isEditModeEnabled} />
                    </div>
                </header>

                <StoryMapContainer onDragEnd={this.onDragEnd} isEditModeEnabled={isEditModeEnabled}>
                    <GoalContainer edit={this.openEditCardDialog} isEditModeEnabled={isEditModeEnabled}>
                        {
                            (goals || []).map(this.renderGoal)
                        }
                    </GoalContainer>

                    <ReleaseContainer edit={this.openEditCardDialog} isEditModeEnabled={isEditModeEnabled}>
                        {
                            (releases || []).map(this.renderRelease)
                        }
                    </ReleaseContainer>
                </StoryMapContainer>

                <EditCard project={project} />
                <EditProject project={project} isEditModeEnabled={isEditModeEnabled} />
                <DeleteProject project={project} />
                <ReleaseFlyout />
            </Page>
        );
    }
}

export default StoryMapLayout;