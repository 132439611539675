import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { getCollections } from 'data/selectors';
import * as Constants from '../constants';
import { toggleEditDialog } from '../actions';
import Loading from 'common/components/Loading';
import CardActions from './CardActions';

export default function Card(props) {
    const firestore = useFirestore();
    const dispatch = useDispatch();

    const [isBusy, setIsBusy] = useState(false);
    const collections = useSelector(getCollections, shallowEqual);

    function edit(e) {
        e.preventDefault();

        dispatch(toggleEditDialog(props.type, props.card));
    }

    async function destroy(e) {
        e.preventDefault();

        if (!props.isEditModeEnabled) {
            return;
        }

        let children = [],
            collectionName = null,
            warning = 'child data';

        if (!collections || !props.card.id) {
            return;
        }

        switch (props.type) {
            case Constants.GOAL:
                let stories = (collections.stories || []).filter(s => s.goalId === props.card.id);
                stories.forEach(s => {
                    children.push({
                        collectionName: 'stories',
                        id: s.id
                    });
                    let tasks = (collections.tasks || []).filter(t => t.storyId === s.id).map(t => ({
                        collectionName: 'tasks',
                        id: t.id
                    }));
                    children = children.concat(tasks);
                });
                collectionName = 'goals';
                warning = 'stories and tasks';
                break;
            case Constants.STORY:
                children = (collections.tasks || []).filter(t => t.storyId === props.card.id).map(t => ({
                    collectionName: 'tasks',
                    id: t.id
                }));
                collectionName = 'stories';
                warning = 'tasks';
                break;
            case Constants.RELEASE:
                throw new Error('Not implemented');
            case Constants.TASK:
                collectionName = 'tasks';
                break;
        }

        if (children && children.length) {
            if (children.length > 100) {
                return alert('WOAH! That\'s a lot of data you\'re trying to delete there. Try another way for now, or contact support.');
            }

            if (!window.confirm(`Performing this delete will delete the current card and associated ${warning}. Continue?`)) {
                return;
            }

            await setIsBusy(true);

            let batch = firestore.batch();

            for (let i = 0, len = children.length; i < len; i++) {
                const child = children[i];
                await firestore.collection(child.collectionName).doc(child.id).delete();
            }

            await batch.commit();
        }
        else {
            await setIsBusy(true);
        }

        await firestore.collection(collectionName).doc(props.card.id).delete();
    }

    if (isBusy) {
        return (
            <div className='card storymap-card'>
                <Loading />
            </div>
        );
    }

    const cardClassName = classNames('card storymap-card storymap-card-actionable', {
        'card-closed': props.card.status === 1
    });

    return (
        <div className={cardClassName} onDoubleClick={edit}>
            {props.card.colors && props.card.colors.length ? (
                <div className='d-flex'>
                    {props.card.colors.map(color => (
                        <div key={color} className='storymap-label-pill' style={{ backgroundColor: Constants.COLOR_MAP[color] }}></div>
                    ))}
                </div>
            ) : null }
            <div>
                {props.card.status !== 1 ? props.card.name : <del>{props.card.name}</del>}
            </div>
            <div>
                {props.card.description ? (
                    <FontAwesomeIcon icon={['far', 'comment-alt']} title='This card has a description, open it up to read more' />
                ) : null}
            </div>

            <CardActions edit={edit} destroy={destroy} isEditModeEnabled={props.isEditModeEnabled} />
        </div>
    );
};