import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Constants from '../constants';
import { toggleBusy as toggleAppBusy } from 'modules/app/actions';

// "bjdibbern@gmail.com"
// name
// "BJ"
// teamId
// "hGbexpfZqF8oVomi23dk"
// type
// "team"
// uid
// "KMz0seZwIXPablXMk6HOF5zXShJ3"

export default function Person({ person, type }) {
    const dispatch = useDispatch();
    const firestore = useFirestore();

    const [isBusy, setIsBusy] = useState(false);
    const user = useSelector(({ auth: { user }}) => user);
    const isCurrentUser = user != null && user.uid == person.id;

    async function toggleBusy() {
        await setIsBusy(!isBusy);
        await dispatch(toggleAppBusy());
    }

    async function approve(e) {
        e.preventDefault();

        await toggleBusy();

        const profileRef = firestore.collection('profiles').doc(person.uid);
        const profile = await profileRef.get();
        await profileRef.set({
            ...profile.data(),
            teamId: person.teamId
        });

        await firestore.collection('requests').doc(person.id).delete();

        await toggleBusy();
    }

    async function deny(e) {
        e.preventDefault();

        await toggleBusy();

        await firestore.collection('requests').doc(person.id).delete();

        await toggleBusy();
    }

    async function remove(e) {
        e.preventDefault();

        await toggleBusy();

        const profileRef = firestore.collection('profiles').doc(person.id);
        const profile = await profileRef.get();
        await profileRef.set({
            ...profile.data(),
            teamId: null
        });

        await toggleBusy();
    }

    return (
        <tr>
            <th scope="row" className='align-middle'>{person.name}</th>
            <td className='align-middle text-muted'>{person.email}</td>
            <td className='align-middle'>
                <div className='d-flex justify-content-end'>
                    {type === Constants.TYPE_REQUEST ? [
                        <a href='#' title='Approve Request' className='btn btn-success btn-circle mr-2' key='approve' onClick={approve}>
                            <FontAwesomeIcon icon={['fas', 'thumbs-up']} />
                        </a>,
                        <a href='#' title='Deny Request' className='btn btn-danger btn-circle' key='reject' onClick={deny}>
                            <FontAwesomeIcon icon={['fas', 'thumbs-down']} />
                        </a>
                    ] : !isCurrentUser ? (
                        <a href='#' title='Remove from team' className='btn btn-danger btn-circle' onClick={remove}>
                            <FontAwesomeIcon icon={['fas', 'trash-alt']} />
                        </a>
                    ) : null}
                </div>
            </td>
        </tr>
    );
}