import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CardActions(props) {
    return (
        <div className='storymap-card-actions'>
            <a href='#' className='fa-action delinkify' title='Edit' onClick={props.edit}>
                <FontAwesomeIcon icon={['fas', props.isEditModeEnabled ? 'pencil-alt' : 'eye']}/>
            </a>
            { props.isEditModeEnabled ? (
                <a href='#' className='fa-action delinkify' title='Delete' onClick={props.destroy}>
                    <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                </a>
            ) : null }
        </div>
    );
};