import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import { firebaseReducer as firebase } from 'react-redux-firebase';
import { firestoreReducer as firestore } from 'redux-firestore';

import { SET_USER } from 'modules/auth/actionTypes';

import { default as app } from './reducer';
import { default as mirror } from './mirrorReducer';
import { reducer as auth } from 'modules/auth';
import { reducer as projects } from 'modules/projects';
import { reducer as storymap } from 'modules/storymap';

const middlewares = [];
if (process.env.NODE_ENV !== 'production') {
    const { logger } = require('redux-logger');
    middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const combinedReducers = combineReducers({
    app,
    auth,
    firebase,
    firestore,
    mirror,
    projects,
    storymap
});

const rootReducer = (state, action) => {
    // Handle logout
    if (action.type === SET_USER && action.payload == null) {
        // // Redux persist cleanup
        // Object.keys(state).forEach(key => {
        //     storage.removeItem(`persist:${key}`);
        // });
        state = undefined;
    }

    let nextState = combinedReducers(state, action);

    if (!action.type.startsWith('@@reduxFirestore')) {
        // Send through normal pipe
        return nextState;
    }
    else {
        // Look for changes to mirror to optimistic state
        let nextState = combinedReducers(state, action),
            mirror = state.mirror || {};

        if (state?.firestore?.data && Object.keys(state.firestore.data).length) {
            nextState.mirror = mirror;

            Object.keys((nextState?.firestore?.data || {})).forEach(key => {
                if (nextState.firestore.data[key] !== state.firestore.data[key]) {
                    nextState.mirror = {
                        ...nextState.mirror,
                        [key]: nextState.firestore.data[key]
                    };
                }
            });
        }

        return nextState;
    }
};

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(...middlewares)
    )
);

export default store;