import * as t from './actionTypes';

export default (state = {}, action) => {
    switch(action.type) {
        case t.FIRESTORE_OPTIMISTIC_UPDATE:
            return {
                ...state,
                [action.payload.key]: action.payload.update
            };
        case t.FIRESTORE_OPTIMISTIC_REVERT:
            return {
                ...state,
                [action.payload.key]: state?.firestore?.data[action.payload.key]
            };
        default:
            return state;
    }
};