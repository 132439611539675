import React from 'react';
import { Helmet } from 'react-helmet';

export default function ApprovalRequired() {
    return (
        <main className='overflow-hidden'>
            <Helmet>
                <title>Fable - Approval Required</title>
            </Helmet>

            <article className='d-flex vw-100 vh-100 justify-content-center align-items-center'>
                <div className='card'>
                    <div className='card-header bg-warning'>
                        <h2>Approval Required</h2>
                    </div>

                    <div className='card-body mt-4'>
                        <p>You requested to join a team. That approval needs to be processed first.</p>
                        <p>Please contact your team administrator to have them approve your request.</p>
                    </div>
                </div>
            </article>
        </main>
    );
}