import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { STORY } from '../constants';
import Card from './Card';

export default function Story(props) {
    return (
        <Draggable draggableId={props.story.id} index={props.index} isDragDisabled={!props.isEditModeEnabled}>
            {provided => (
                <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Card type={STORY} card={props.story} isEditModeEnabled={props.isEditModeEnabled} />
                </div>
            )}
        </Draggable>
    );
};