export const GOAL = 'GOAL';
export const RELEASE = 'RELEASE';
export const STORY = 'STORY';
export const TASK = 'TASK';
export const GOAL_NO_STORY_PLACEHOLDER = 'GOAL_NO_STORY_PLACEHOLDER';
export const STORY_CREATE_PLACEHOLDER = 'STORY_CREATE_PLACEHOLDER';
export const GOAL_COLLAPSE_PLACEHOLDER = 'GOAL_COLLAPSE_PLACEHOLDER';

export const COLORS = [
    {
        color: '#1e7145',
        colorName: 'green'
    },
    {
        color: '#2d89ef',
        colorName: 'blue'
    },
    {
        color: '#ffc40d',
        colorName: 'yellow'
    },
    {
        color: '#b91d47',
        colorName: 'red'
    },
    {
        color: '#9f00a7',
        colorName: 'purple'
    },
    {
        color: '#00aba9',
        colorName: 'teal'
    }
];

export const COLOR_MAP = {
    green: '#1e7145',
    blue: '#2d89ef',
    yellow: '#ffc40d',
    red: '#b91d47',
    purple: '#9f00a7',
    teal: '#00aba9'
};