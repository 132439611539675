import React from 'react';

import Person from './Person';

export default function People({ people, type }) {
    return (
        <table className='table table-striped'>
            <colgroup>
                <col span='1' style={{ width: '40%' }} />
                <col span='1' style={{ width: '50%' }} />
                <col span='1' style={{ width: '10%' }} />
            </colgroup>
            <thead>
                <tr>
                    <th scope='col'>Name</th>
                    <th scope='col'>Email</th>
                    <th scope='col' className='text-right'>Actions</th>
                </tr>
            </thead>
            <tbody>
                {(people || []).map(person => <Person person={person} type={type} key={person.id} />)}
            </tbody>
        </table>
    );
}