import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { Link, Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import Page from 'common/components/Page';
import { setAuthLoaded, setUser } from '../actions';
import { getAuthExists } from 'data/selectors';

export default function Login() {
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const { handleSubmit, register, formState } = useForm();

    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState(null);
    const authExists = useSelector(getAuthExists);

    if (authExists) {
        let location = window.location.search.split('=')[1] || '/projects';
        return <Redirect to={location} />;
    }

    async function login(values) {
        if (!values.email || !values.password) {
            return;
        }

        await setIsBusy(true);
        await setError(null);

        // Reset auth status to unloaded since we're about to change it
        // NOTE: *not* doing this messes with <RequireAuth />'s redirects
        await dispatch(setAuthLoaded(false));

        try {
            const response = await firebase.auth()
                .signInWithEmailAndPassword(values.email, values.password);

            return await dispatch(setUser({
                uid: response.user.uid,
                email: response.user.email,
                displayName: response.user.displayName
            }));
        } catch (e) {
            await setError(e?.message || 'Unknown failure');
            await setIsBusy(false);

            await dispatch(setAuthLoaded(true));
        }
    }

    const formClassName = classNames('needs-validation', {
        'was-validated': formState.isSubmitted
    });
    const labelClassName = 'col-5 col-sm-4 col-md-3 col-lg-2 col-form-label';
    const inputClassName = 'form-control form-control-sm col-7 col-sm-8 col-md-9 col-lg-10';
    const helpClassName = 'form-text text-muted col-7 col-sm-8 col-md-9 col-lg-10 offset-5 offset-sm-4 offset-md-3 offset-lg-2';

    return (
        <Page title='Login' className='row justify-content-sm-center mb-4'>
            <div className='col-12 col-md-8 col-lg-5'>
                <div className='card'>
                    <div className='card-header'>
                        <h2>Login</h2>
                    </div>

                    <div className='card-body mt-4'>
                        <form onSubmit={handleSubmit(login)} className={formClassName} noValidate>
                            <div className='form-group form-row'>
                                <label htmlFor='email' className={labelClassName}>Email</label>
                                <input type='email' className={inputClassName} id='email' name='email' required disabled={isBusy} ref={register({ required: 'Required' })} />
                            </div>
                            <div className='form-group form-row'>
                                <label htmlFor='password' className={labelClassName}>Password</label>
                                <input type='password' className={inputClassName} id='password' name='password' required disabled={isBusy} ref={register({ required: 'Required' })} />
                                <small id='passwordHelp' className={helpClassName}>
                                    <Link to='/reset'>Forgot your password?</Link>
                                </small>
                            </div>

                            {
                                error ? (
                                    <div className='form-group row'>
                                        <span className='col-12 text-danger'>{error}</span>
                                    </div>
                                ) : null
                            }

                            <hr />

                            <div className='position-relative'>
                                <button className='btn btn-primary' type='submit' disabled={isBusy || formState.isSubmitting}>
                                    {isBusy || formState.isSubmitting ?
                                        <span className='spinner-border spinner-border-sm mr-1'></span> : null}
                                    {isBusy || formState.isSubmitting ?
                                        'Working on it...' : 'Login'}
                                </button>
                                &nbsp;or&nbsp;<Link to='/register'>register</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Page>
    );
}